//  covid-panel.reducer.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import * as types from './heat-resources.actionType';

const persistConfig = {
  key: 'HeatResourcesPanel',
  storage,
  whitelist: [
    /* keys to be persisted */
  ],
};

const initialState = {
  data: undefined,
};

function HeatResourcesPanel(state = initialState, action) {
  // NOSONAR
  switch (action.type) {  // eslint-disable-line
    case types.HEATRESOURCESPANEL_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, HeatResourcesPanel);
