//  password.route.js
//  Project hpi
//
//  Created by Axis Maps on 3/29/2022, 2:06:52 PM
//

export default {
  path: '/password/:token',
  component: () => import('./password.component'),
};
