//  covid-panel.epic.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import * as types from './heat-resources.actionType';
import * as actions from './heat-resources.action';
import { csvParse } from 'd3';

export default (action$, store, { of, ajax }) =>
  action$.ofType(types.HEATRESOURCESPANEL_LOAD_DATA).mergeMap(() => {
    return ajax({
      url: `program-metadata.csv`,
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
      responseType: 'text/csv',
    }).mergeMap(res => {
      return of(actions.dataSuccess(csvParse(res.response)));
    });
  });
