//  index.js
//  Project hpi
//
//  Created by Axis Maps on 4/29/2020, 11:17:06 AM
//

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./pool-learn-more.component'),
  loading: () => null,
});
