//  login.route.js
//  Project hpi
//
//  Created by Axis Maps on 7/26/2021, 11:43:13 AM
//

export default {
  path: '/login',
  component: () => import('./login.component'),
};
