//  index.js
//  Project hpi
//
//  Created by Axis Maps on 4/27/2020, 4:00:55 PM
//

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./heat-resources.component'),
  loading: () => null,
});
