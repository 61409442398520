//  covid-panel.action.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import * as types from './covid-panel.actionType';

export function test(payload) {
  return {
    type: types.COVIDPANEL_TEST,
    payload,
  };
}

export function testSuccess(payload) {
  return {
    type: types.COVIDPANEL_TEST_SUCCESS,
    payload,
  };
}

export function loadCovidData(payload) {
  return {
    type: types.COVIDPANEL_LOAD_DATA,
    payload,
  };
}

export function dataSuccess(payload) {
  return {
    type: types.COVIDPANEL_DATA_SUCCESS,
    payload,
  };
}
