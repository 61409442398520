//  covid-panel.epic.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import * as types from './covid-panel.actionType';
import * as actions from './covid-panel.action';
import { csvParse } from 'd3';

export default (action$, store, { of, ajax, forkJoin }) =>
  action$.ofType(types.COVIDPANEL_LOAD_DATA).mergeMap(() => {
    const requests = [
      {
        key: 'covidCases',
        req: ajax({
          url: `covid_cases.csv`,
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv',
          },
          responseType: 'text/csv',
        }),
      },
      {
        key: 'covidDeaths',
        req: ajax({
          url: `covid_deaths.csv`,
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv',
          },
          responseType: 'text/csv',
        }),
      },
      {
        key: 'covidDeathRate',
        req: ajax({
          url: `covid_death_rate.csv`,
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv',
          },
          responseType: 'text/csv',
        }),
      },
      {
        key: 'covidTwoWeekDeathRate',
        req: ajax({
          url: `covid_two_week_death_rate.csv`,
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv',
          },
          responseType: 'text/csv',
        }),
      },
      {
        key: 'covidTwoWeekCaseRate',
        req: ajax({
          url: `covid_two_week_case_rate.csv`,
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv',
          },
          responseType: 'text/csv',
        }),
      },
    ];
    return forkJoin(requests.map(r => r.req)).mergeMap(res => {
      const data = res.reduce((obj, r, i) => {
        return {
          ...obj,
          [requests[i].key]: csvParse(r.response),
        };
      }, {});
      return of(actions.dataSuccess(data));
    });
  });
