import * as types from './main.actionType';

/**
 * Data loading */

export function loadIndicatorList(payload) {
  return {
    type: types.MAIN_LOAD_INDICATOR_LIST,
    payload,
  };
}

export function loadIndicatorListSuccess(payload) {
  return {
    type: types.MAIN_LOAD_INDICATOR_LIST_SUCCESS,
    payload,
  };
}

export function loadGeographiesList(payload) {
  return {
    type: types.MAIN_LOAD_GEOGRAPHIES_LIST,
    payload,
  };
}

export function loadGeographiesListSuccess(payload) {
  return {
    type: types.MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS,
    payload,
  };
}

export function geographiesStatsSuccess(payload) {
  return {
    type: types.MAIN_GEOGRAPHIES_STATS_SUCCESS,
    payload,
  };
}

export function loadIndicatorData(payload) {
  return {
    type: types.MAIN_LOAD_INDICATOR_DATA,
    payload,
  };
}

export function loadPoolData(payload) {
  return {
    type: types.MAIN_LOAD_POOL_DATA,
    payload,
  };
}

export function loadIndicatorDataSuccess(payload) {
  return {
    type: types.MAIN_LOAD_INDICATOR_DATA_SUCCESS,
    payload,
  };
}

export function loadHistogramData(payload) {
  return {
    type: types.MAIN_LOAD_HISTOGRAM_DATA,
    payload,
  };
}

export function histogramDataSuccess(payload) {
  return {
    type: types.MAIN_HISTOGRAM_DATA_SUCCESS,
    payload,
  };
}

export function setIndicator(payload) {
  return {
    type: types.MAIN_SET_INDICATOR,
    payload,
  };
}

/**
 * Feature data */

export function loadFeatureConditionData(payload) {
  return {
    type: types.MAIN_LOAD_FEATURE_CONDITION_DATA,
    payload,
  };
}

export function featureConditionDataSuccess(payload) {
  return {
    type: types.MAIN_FEATURE_CONDITION_DATA_SUCCESS,
    payload,
  };
}

export function loadFeatureMetadata(payload) {
  return {
    type: types.MAIN_LOAD_FEATURE_METADATA,
    payload,
  };
}

export function featureMetadataSuccess(payload) {
  return {
    type: types.MAIN_FEATURE_METADATA_SUCCESS,
    payload,
  };
}

/**
 * Change geography type */

export function setGeography(payload) {
  return {
    type: types.MAIN_SET_GEOGRAPHY,
    payload,
  };
}

export function setGeographyAndIndicator(payload) {
  return {
    type: types.MAIN_SET_GEOGRAPHY_AND_INDICATOR,
    payload,
  };
}

/**
 * Highlight/select feature */

export function highlightFeature(payload) {
  return {
    type: types.MAIN_HIGHLIGHT_FEATURE,
    payload,
  };
}

export function selectFeature(payload) {
  return {
    type: types.MAIN_SELECT_FEATURE,
    payload,
  };
}

export function selectAddress(payload) {
  return {
    type: types.MAIN_SELECT_ADDRESS,
    payload,
  };
}

export function selectFeatureSuccess(payload) {
  return {
    type: types.MAIN_SELECT_FEATURE_SUCCESS,
    payload,
  };
}

/**
 * Choropleth classification */
export function setAttribute(payload) {
  return {
    type: types.MAIN_SET_ATTRIBUTE,
    payload,
  };
}

export function setClassificationMethod(payload) {
  return {
    type: types.MAIN_SET_CLASSIFICATION_METHOD,
    payload,
  };
}

export function setClassification(payload) {
  return {
    type: types.MAIN_SET_CLASSIFICATION,
    payload,
  };
}

export function setChoroplethFilter(payload) {
  return {
    type: types.MAIN_SET_CHOROPLETH_FILTER,
    payload,
  };
}

export function setIndicatorFilter(payload) {
  return {
    type: types.MAIN_SET_INDICATOR_FILTER,
    payload,
  };
}

export function setIndicatorFilterSuccess(payload) {
  return {
    type: types.MAIN_SET_INDICATOR_FILTER_SUCCESS,
    payload,
  };
}

export function setDemographicsFilter(payload) {
  return {
    type: types.MAIN_SET_DEMOGRAPHICS_FILTER,
    payload,
  };
}

export function setDemographicsFilterSuccess(payload) {
  return {
    type: types.MAIN_SET_DEMOGRAPHICS_FILTER_SUCCESS,
    payload,
  };
}

/**
 * probe */

export function setView(payload) {
  return {
    type: types.MAIN_SET_VIEW,
    payload,
  };
}

export function setSearchTerm(payload) {
  return {
    type: types.MAIN_SET_SEARCH_TERM,
    payload,
  };
}

export function setAddressSearchResults(payload) {
  return {
    type: types.MAIN_SET_ADDRESS_SEARCH_RESULTS,
    payload,
  };
}

/**
 * set active panel */

export function setProbeData(payload) {
  return {
    type: types.MAIN_SET_PROBE_DATA,
    payload,
  };
}

export function toggleTools(payload) {
  return {
    type: types.MAIN_TOGGLE_TOOLS,
    payload,
  };
}

export function setPanelChanged(payload) {
  return {
    type: types.MAIN_SET_PANEL_CHANGED,
    payload,
  };
}

/**
 * pooling */

export function addToPool(payload) {
  return {
    type: types.MAIN_ADD_TO_POOL,
    payload,
  };
}

export function clearActivePool(payload) {
  return {
    type: types.MAIN_CLEAR_ACTIVE_POOL,
    payload,
  };
}

export function removeFromPool(payload) {
  return {
    type: types.MAIN_REMOVE_FROM_POOL,
    payload,
  };
}

export function createPool(payload) {
  return {
    type: types.MAIN_CREATE_POOL,
    payload,
  };
}

export function poolSuccess(payload) {
  return {
    type: types.MAIN_POOL_SUCCESS,
    payload,
  };
}

export function deletePool(payload) {
  return {
    type: types.MAIN_DELETE_POOL,
    payload,
  };
}

/**
 * rank */

export function getRankData(payload) {
  return {
    type: types.MAIN_GET_RANK_DATA,
    payload,
  };
}

export function rankDataSuccess(payload) {
  return {
    type: types.MAIN_RANK_DATA_SUCCESS,
    payload,
  };
}

/**
 * login */

export function login(payload) {
  return {
    type: types.MAIN_LOGIN,
    payload,
  };
}

export function loginSuccess(payload) {
  return {
    type: types.MAIN_LOGIN_SUCCESS,
    payload,
  };
}

export function setLoginError(payload) {
  return {
    type: types.MAIN_LOGIN_ERROR,
    payload,
  };
}

export function logoutSuccess(payload) {
  return {
    type: types.MAIN_LOGOUT_SUCCESS,
    payload,
  };
}

export function logout(payload) {
  return {
    type: types.MAIN_LOGOUT,
    payload,
  };
}

export function getAPIKey(payload) {
  return {
    type: types.MAIN_GET_API_KEY,
    payload,
  };
}

export function getAPIKeySuccess(payload) {
  return {
    type: types.MAIN_API_KEY_SUCCESS,
    payload,
  };
}

/**
 * saved views */

export function saveNewView(payload) {
  return {
    type: types.MAIN_SAVE_VIEW,
    payload,
  };
}

export function getSavedViews(payload) {
  return {
    type: types.MAIN_GET_VIEWS,
    payload,
  };
}

export function receivedViews(payload) {
  return {
    type: types.MAIN_RECEIVED_VIEWS,
    payload,
  };
}

export function deleteView(payload) {
  return {
    type: types.MAIN_DELETE_VIEW,
    payload,
  };
}

export function loadSavedView(payload) {
  return {
    type: types.MAIN_LOAD_SAVED_VIEW,
    payload,
  };
}

export function getViewById(payload) {
  return {
    type: types.MAIN_GET_VIEW_BY_ID,
    payload,
  };
}

/**
 * import */

export function getLayers(payload) {
  return {
    type: types.MAIN_GET_LAYERS,
    payload,
  };
}
export function layersSuccess(payload) {
  return {
    type: types.MAIN_LAYERS_SUCCESS,
    payload,
  };
}
export function uploadFile(payload) {
  return {
    type: types.MAIN_UPLOAD_FILE,
    payload,
  };
}

export function uploadError(payload) {
  return {
    type: types.MAIN_UPLOAD_ERROR,
    payload,
  };
}

export function uploadSuccess(payload) {
  return {
    type: types.MAIN_UPLOAD_SUCCESS,
    payload,
  };
}

export function saveLayer(payload) {
  return {
    type: types.MAIN_SAVE_LAYER,
    payload,
  };
}

export function saveLayerSuccess(payload) {
  return {
    type: types.MAIN_SAVE_LAYER_SUCCESS,
    payload,
  };
}

export function saveLayerError(payload) {
  return {
    type: types.MAIN_SAVE_LAYER_ERROR,
    payload,
  };
}

export function deleteLayer(payload) {
  return {
    type: types.MAIN_DELETE_LAYER,
    payload,
  };
}

export function loadLayerData(payload) {
  return {
    type: types.MAIN_LOAD_LAYER_DATA,
    payload,
  };
}

export function addOverlay(payload) {
  return {
    type: types.MAIN_ADD_OVERLAY,
    payload,
  };
}

export function removeOverlay(payload) {
  return {
    type: types.MAIN_REMOVE_OVERLAY,
    payload,
  };
}

export function setCurrentUserLayer(payload) {
  return {
    type: types.MAIN_SET_CURRENT_USER_LAYER,
    payload,
  };
}

/**
 * export */
export function exportMapImage(payload) {
  return {
    type: types.MAIN_EXPORT_MAP,
    payload,
  };
}

export function exportSuccess(payload) {
  return {
    type: types.EXPORT_MAP_SUCCESS,
    payload,
  };
}

/**
 * policies panel */
export function getPolicies(payload) {
  return {
    type: types.MAIN_GET_POLICIES,
    payload,
  };
}

export function policiesSuccess(payload) {
  return {
    type: types.MAIN_POLICIES_SUCCESS,
    payload,
  };
}

export function setSelectedPolicy(payload) {
  return {
    type: types.MAIN_SET_SELECTED_POLICY,
    payload,
  };
}

export function setCustomScore(payload) {
  return {
    type: types.MAIN_SET_CUSTOM_SCORE,
    payload,
  };
}

export function customScoreSuccess(payload) {
  return {
    type: types.MAIN_CUSTOM_SCORE_SUCCESS,
    payload,
  };
}

export function setMultipleVulnerabilities(payload) {
  return {
    type: types.MAIN_SET_MULTIPLE_VULNERABILITIES,
    payload,
  };
}

export function multipleVulnerabilitiesSuccess(payload) {
  return {
    type: types.MAIN_MULTIPLE_VULNERABILITIES_SUCCESS,
    payload,
  };
}

export function setEnv(payload) {
  return {
    type: types.MAIN_SET_ENV,
    payload,
  };
}

export function setDefaultPanel(payload) {
  return {
    type: types.MAIN_SET_DEFAULT_PANEL,
    payload,
  };
}

/*
 * containers/main/action.js : MAIN_TEST action factory
 * @param {any} payload the payload of the action
 * @returns {Object} the test action object
 */
export function test(payload) {
  return {
    type: types.MAIN_TEST,
    payload,
  };
}

/*
 * containers/main/action.js : MAIN_TEST_SUCCESS action factory
 * @param {any} payload the payload of the action
 * @returns {Object} the testSuccess action object
 */
export function testSuccess(payload) {
  return {
    type: types.MAIN_TEST_SUCCESS,
    payload,
  };
}

/*
 * containers/main/action.js : MAIN_TEST_ERROR action factory
 * @param {any} payload the payload of the action
 * @returns {Object} the testError action object
 */
export function testError(payload) {
  return {
    type: types.MAIN_TEST_ERROR,
    payload,
  };
}

/*
 * containers/main/action.js : MAIN_TEST_CANCEL action factory
 * @param {any} payload the payload of the action
 * @returns {Object} the testCancel action object
 */
export function testCancel(payload) {
  return {
    type: types.MAIN_TEST_CANCEL,
    payload,
  };
}
