//  covid-panel.reducer.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import * as types from './covid-panel.actionType';

const persistConfig = {
  key: 'CovidPanel',
  storage,
  whitelist: [
    /* keys to be persisted */
  ],
};

const initialState = {
  data: {},
};

function CovidPanel(state = initialState, action) {
  // NOSONAR
  switch (action.type) {  // eslint-disable-line
    case types.COVIDPANEL_TEST:
      return {
        ...state,
        loading: true,
      };

    case types.COVIDPANEL_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case types.COVIDPANEL_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, CovidPanel);
