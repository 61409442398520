import phaLogo from '@client/assets/pha-logo-white.png';
import uclaLogo from '@client/assets/ucla-logo.png';
import utahLogo from '@client/assets/utah-logo.png';

export const downloadable = true;

export const getEnv = window => {
  const title = window.env.CLIENT_APP_TITLE;
  const bounds = window.env.CLIENT_MAX_BOUNDS;
  const topGeog = window.env.CLIENT_TOP_GEOGRAPHY_COMPARISON;
  const b =
    typeof bounds === 'string' ? window.env.CLIENT_MAX_BOUNDS.split(',').map(d => +d) : bounds;
  return {
    appTitle: title,
    topGeography: topGeog,
    maxBounds: [
      [b[0], b[1]],
      [b[2], b[3]],
    ],
    mapboxApiAccessToken: window.env.CLIENT_MAPBOX_TOKEN,
    componentSet: window.env.CLIENT_COMPONENT_SET,
    tileCache: window.env.CLIENT_TILE_CACHE,
    headerColor: window.env.CLIENT_HEADER_COLOR,
    geocodingFilter: window.env.CLIENT_GEOCODING_FILTER,
    apiURL: window.env.CLIENT_API_URL,
  };
};

const logos = {
  pha: { src: phaLogo, key: 'pha', alt: 'Public Health Alliance of Southern California' },
  ucla: { src: uclaLogo, key: 'ucla', alt: 'UCLA Luskin Center for Innovation' },
  utah: { src: utahLogo, key: 'utah', alt: 'Utah Department of Health and Human Services' },
};

export const getLogos = componentSet => {
  if (componentSet === 'heat') return [logos.pha, logos.ucla];
  if (componentSet === 'utah') return [logos.pha, logos.utah];
  return [logos.pha];
};

export const getPanelsToInclude = componentSet => {
  if (componentSet === 'heat') {
    return [
      'climate',
      'multiple-vulnerabilities',
      'heat-resources',
      'pool-geographies',
      'view-indicators',
      'edit-display',
      'rank-by-geography',
      'upload-data',
      'filter',
      'demographics-filter',
      'hpi3',
      'covid',
      'account',
    ];
  }
  if (componentSet === 'utah') {
    return [
      'community-conditions',
      'pool-geographies',
      'view-indicators',
      'edit-display',
      'compare-indicators',
      'rank-by-geography',
      'upload-data',
      'filter',
      'demographics-filter',
      'policy-opportunities',
      'account',
    ];
  }
  return [
    'community-conditions',
    'covid',
    // 'predict-life-expectancy',
    'pool-geographies',
    'view-indicators',
    'edit-display',
    'compare-indicators',
    'rank-by-geography',
    'upload-data',
    'filter',
    'demographics-filter',
    'policy-opportunities',
    'heat',
    'hpi2',
    'account',
    // 'custom-score',

    // ... list all (and only) panels to inbclude, by key or title (see panelsConfig below for valid options)
  ];
};

export const getDefaultPanel = componentSet => {
  if (componentSet === 'heat') {
    return {
      defaultIndicatorSelected: 'climate',
      otherIndicatorSelected: 'view-indicators',
    };
  }
  return {
    // show this one if the default indicator (HPI score?) is selected
    defaultIndicatorSelected: 'community-conditions',
    // show this one if a different indicator is selected
    otherIndicatorSelected: 'view-indicators',
  };
};

// the default panel(s) to open if a map feature is selected (both are optional)
export const defaultPanel = {
  // show this one if the default indicator (HPI score?) is selected
  defaultIndicatorSelected: 'Community Conditions',
  // show this one if a different indicator is selected
  otherIndicatorSelected: 'View Indicators',
};

// panel titles, colors, descriptions
// descriptions can include env variables (e.g. topGeography) enclosed in curly brackets
// properties can be set to env variables like so -- url: { env: 'apiURL' }
const panelsConfig = [
  {
    title: 'Community Conditions', // title that appears in the panel itself
    category: 'Explore',
    buttonColor: '#E6EFFF', // button background color (lighter)
    color: '#3562AB', // text and border color (darker)
    buttonLabel: 'Get Community Conditions', // label on button in main menu
    description:
      'Explore the {topGeography} Healthy Places Index score to examine neighborhood measures linked to health outcomes, and view key indicators of diversity and equity.',
    key: 'community-conditions', // DO NOT CHANGE 'key' value
  },
  {
    title: 'Climate + Community Details',
    category: 'Explore',
    buttonColor: '#E6EFFF',
    color: '#3562AB',
    buttonLabel: 'Climate + Community Details',
    description:
      'Learn more about projected heat exposure and underlying heat vulnerability and resilience characteristics of a community.',
    key: 'climate', // DO NOT CHANGE 'key' value
  },
  {
    title: 'Resources to Address Extreme Heat',
    category: 'Explore',
    buttonColor: '#FFE6E6',
    color: '#C41414',
    buttonLabel: 'Resources to Address Extreme Heat',
    description: 'View programs and funding opportunities to address extreme heat.',
    key: 'heat-resources', // DO NOT CHANGE 'key' value
  },
  {
    title: 'Visualize Multiple Vulnerability Indicators',
    category: 'Customize',
    buttonColor: '#FAF9DE',
    color: '#ABA533',
    buttonLabel: 'Visualize Multiple Vulnerability Indicators',
    description:
      'Visualize the intersection of heat exposure and selected vulnerability indicators.',
    key: 'multiple-vulnerabilities', // DO NOT CHANGE 'key' value
  },
  {
    title: 'Filter by Indicator',
    category: 'Explore',
    buttonColor: '#EEFAE1',
    color: '#38610A',
    buttonLabel: 'Filter by Indicator',
    description:
      'Filter the map to find communities that meet your criteria. The map can be filtered by HPI score, HPI indicator, and most decision support layers.',
    key: 'filter', // DO NOT CHANGE
  },
  {
    title: 'Filter by Race/Ethnicity',
    category: 'Explore',
    buttonColor: '#e5fff4',
    color: '#006649',
    buttonLabel: 'Filter by Race/Ethnicity',
    description:
      'Filter the map to find communities of a specific race, ethnicity, and/or ancestry. Filter thresholds can be set by proportion or total population.',
    key: 'demographics-filter', // DO NOT CHANGE
  },
  {
    title: 'View Indicators',
    category: 'View data',
    buttonColor: '#F0EBFF',
    color: '#6C51B5',
    buttonLabel: 'View Indicators',
    description:
      'Map individual indicators, including HPI indicators and our extensive decision support layers.',
    key: 'view-indicators', // DO NOT CHANGE
  },
  {
    title: 'Life Span Predictor',
    view: 'lifeexpectancy',
    category: 'Explore',
    buttonColor: '#FFE6E6',
    color: '#DE4E4E',
    buttonLabel: 'Life Span Predictor',
    description:
      'Predict life expetancy at birth for a selected area based on chosen indicator values.',
    key: 'predict-life-expectancy', // DO NOT CHANGE
  },
  {
    title: 'Pool Geographies',
    category: 'Organize',
    buttonColor: '#FBF0FC',
    color: '#B14EBA',
    buttonLabel: 'Pool Geographies',
    description: 'Join multiple areas into a single entity and see combined data.',
    key: 'pool-geographies', // DO NOT CHANGE
    hideOnMobile: true,
  },
  {
    title: 'Edit Display',
    category: 'Customize',
    buttonColor: '#E1FAFA',
    color: '#31A3A3',
    buttonLabel: 'Edit Display',
    description: 'Change the appearance and method by which areas are colored on the map.',
    key: 'edit-display', // DO NOT CHANGE
  },
  {
    title: 'Compare Data',
    category: 'View data',
    buttonColor: '#F0FCFF',
    color: '#24A0BF',
    buttonLabel: 'Compare Data',
    description: 'Compare two indicators in side-by-side maps.',
    key: 'compare-indicators', // DO NOT CHANGE,
    hideOnMobile: true,
  },
  {
    title: 'Rank By Geography',
    category: 'Organize',
    buttonColor: '#E1FAE2',
    color: '#128216',
    buttonLabel: 'Rank Geographies',
    description:
      'Show all the areas within a larger area (for example tracts within a county), ranked by the indicator currently active on the map.',
    key: 'rank-by-geography', // DO NOT CHANGE
  },
  {
    title: 'Upload Data',
    category: 'View data',
    buttonColor: '#FFF4E6',
    color: '#B36C09',
    buttonLabel: 'Upload Data',
    description: 'Add your own data to the map',
    key: 'upload-data', // DO NOT CHANGE
    hideOnMobile: true,
  },
  {
    title: 'Policy Opportunities',
    category: 'Explore',
    buttonColor: '#F0F2D0',
    color: '#7B7D5F',
    buttonLabel: 'Policy Opportunities',
    description: 'Discover policy opportunities to improve community health outcomes.',
    key: 'policy-opportunities', // DO NOT CHANGE
  },
  {
    title: 'Create Custom Score',
    category: 'Customize',
    buttonColor: '#FAF9DE',
    color: '#ABA533',
    buttonLabel: 'Create Custom Score',
    description: 'Choose indicators and weighting to calculate a custom score shown on the map.',
    key: 'custom-score', // DO NOT CHANGE
  },
  {
    title: 'CA HPI Extreme Heat Edition',
    category: 'Additional HPI Tools',
    buttonColor: '#f2e0ce',
    color: '#98693a',
    buttonLabel: 'CA HPI Extreme Heat Edition',
    description:
      'Explore datasets on projected heat exposure for the State of California, place-based indicators measuring community conditions, and sensitive populations.',
    key: 'heat', // DO NOT CHANGE,
    url: 'https://heat.healthyplacesindex.org/',
  },
  // {
  //   title: 'CA Healthy Places Index 2.0',
  //   category: 'Additional HPI Tools',
  //   buttonColor: '#b6cef3',
  //   color: '#3562ab',
  //   buttonLabel: 'CA Healthy Places Index 2.0',
  //   description: 'Access the legacy HPI 2.0 platform. Available until July 1, 2022.',
  //   key: 'hpi2', // DO NOT CHANGE,
  //   url: 'https://hpi2.healthyplacesindex.org/',
  // },
  {
    title: 'CA Healthy Places Index 3.0',
    category: 'Additional HPI Tools',
    buttonColor: '#b6cef3',
    color: '#3562ab',
    buttonLabel: 'CA Healthy Places Index 3.0',
    description: 'Access the main HPI 3.0 platform.',
    key: 'hpi3', // DO NOT CHANGE,
    url: 'https://map.healthyplacesindex.org/',
  },
  {
    title: 'COVID-19 Impacts',
    category: 'Additional HPI Tools',
    buttonColor: '#f2cfce',
    color: '#993d3a',
    buttonLabel: 'COVID-19 Impacts',
    description:
      'View key measures of COVID-19, including cases, deaths, and deaths by race/ethnicity.',
    key: 'covid', // DO NOT CHANGE
    url: 'https://covid19.healthyplacesindex.org/',
  },
  {
    title: 'Download Data',
    category: 'Additional HPI Tools',
    buttonColor: '#dedede',
    color: '#666666',
    buttonLabel: 'Download Data',
    description: 'Sign up for an API key to download HPI data',
    key: 'account', // DO NOT CHANGE,
    url: { env: 'apiURL' },
  },
];

export const panelData = panelsConfig; // .filter(p => panelsToInclude.includes(p.key) || panelsToInclude.includes(p.title));

// defualt indicator, geography, and classification method
export const defaultIndicatorName = 'HPI Score';
export const defaultGeographyName = 'Tracts';
export const defaultClassification = 'Quartiles'; // quartiles, equal interval, or natural breaks

// define some color ramps to assign to various uses
const reds = ['#FEEDDF', '#FCBD89', '#FB8D46', '#D74819']; // e.g. for extreme heat
const blues = ['#EFF3FF', '#BDD7E7', '#6BAED6', '#2171B5']; // e.g. for multiple vulnerabilities
const purples = ['#edf8fb', '#b3cde3', '#8c96c6', '#88419d']; // a "neutral" scheme perhaps?
const hpiColors = [
  'rgb(33, 113, 181)',
  'rgb(158, 202, 225)',
  'rgb(166, 219, 160)',
  'rgb(0, 136, 55)',
]; // blue-green HPI colors

// in many cases, these two are the main color schemes
export const divergingColors = hpiColors; // generally, only for the HPI indicators
export const valueColors = purples; // for other indicators

// these two may be obsolete, but best to leave here them until verification
export const sequentialColors = reds;
export const percentileColors = hpiColors;

export const heatColors = reds; // for climate indicators in UCLA heat tool
export const vulnerabilityColors = blues; // 'visualize multiple vulnerabilites' in UCLA heat tool
