//  reset.route.js
//  Project hpi
//
//  Created by Axis Maps on 3/29/2022, 1:45:13 PM
//

export default {
  path: '/reset',
  component: () => import('./reset.component'),
};
