//  signup.route.js
//  Project hpi
//
//  Created by Axis Maps on 3/29/2022, 9:27:15 AM
//

export default {
  path: '/signup',
  component: () => import('./signup.component'),
};
