//  index.js
//  Project hpi
//
//  Created by Axis Maps on 6/9/2020, 1:32:57 PM
//

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./rank-panel.component'),
  loading: () => null,
});
