export const MAIN_TEST = 'MAIN_TEST';
export const MAIN_TEST_ERROR = 'MAIN_TEST_ERROR';
export const MAIN_TEST_SUCCESS = 'MAIN_TEST_SUCCESS';
export const MAIN_TEST_CANCEL = 'MAIN_TEST_CANCEL';

export const MAIN_LOAD_INDICATOR_LIST = 'MAIN_LOAD_INDICATOR_LIST';
export const MAIN_LOAD_INDICATOR_LIST_SUCCESS = 'MAIN_LOAD_INDICATOR_LIST_SUCCESS';
export const MAIN_LOAD_GEOGRAPHIES_LIST = 'MAIN_LOAD_GEOGRAPHIES_LIST';
export const MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS = 'MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS';
export const MAIN_GEOGRAPHIES_STATS_SUCCESS = 'MAIN_GEOGRAPHIES_STATS_SUCCESS';
export const MAIN_LOAD_INDICATOR_DATA = 'MAIN_LOAD_INDICATOR_DATA';
export const MAIN_LOAD_POOL_DATA = 'MAIN_LOAD_POOL_DATA';
export const MAIN_LOAD_INDICATOR_DATA_SUCCESS = 'MAIN_LOAD_INDICATOR_DATA_SUCCESS';

export const MAIN_LOAD_HISTOGRAM_DATA = 'MAIN_LOAD_HISTOGRAM_DATA';
export const MAIN_HISTOGRAM_DATA_SUCCESS = 'MAIN_HISTOGRAM_DATA_SUCCESS';

export const MAIN_SET_INDICATOR = 'MAIN_SET_INDICATOR';
export const MAIN_SET_GEOGRAPHY = 'MAIN_SET_GEOGRAPHY';
export const MAIN_SET_GEOGRAPHY_AND_INDICATOR = 'MAIN_SET_GEOGRAPHY_AND_INDICATOR';

// highlight feature on map mouseover
export const MAIN_HIGHLIGHT_FEATURE = 'MAIN_HIGHLIGHT_FEATURE';
// select feature on map click
export const MAIN_SELECT_FEATURE = 'MAIN_SELECT_FEATURE';
export const MAIN_SELECT_ADDRESS = 'MAIN_SELECT_ADDRESS';
export const MAIN_SELECT_FEATURE_SUCCESS = 'MAIN_SELECT_FEAUTRE_SUCCESS';

export const MAIN_SET_PROBE_DATA = 'MAIN_SET_PROBE_DATA';

// the active panel
export const MAIN_SET_VIEW = 'MAIN_SET_VIEW';
export const MAIN_SET_PANEL_CHANGED = 'MAIN_SET_PANEL_CHANGED';
export const MAIN_TOGGLE_TOOLS = 'MAIN_TOGGLE_TOOLS';

export const MAIN_ADD_TO_POOL = 'MAIN_ADD_TO_POOL';
export const MAIN_REMOVE_FROM_POOL = 'MAIN_REMOVE_FROM_POOL';
export const MAIN_CLEAR_ACTIVE_POOL = 'MAIN_CLEAR_ACTIVE_POOL';
export const MAIN_CREATE_POOL = 'MAIN_CREATE_POOL';
export const MAIN_POOL_SUCCESS = 'MAIN_POOL_SUCCESS';
export const MAIN_DELETE_POOL = 'MAIN_DELETE_POOL';

// search
export const MAIN_SET_SEARCH_TERM = 'MAIN_SET_SEARCH_TERM';
export const MAIN_SET_ADDRESS_SEARCH_RESULTS = 'MAIN_SET_ADDRESS_SEARCH_RESULTS';

// feature data
export const MAIN_LOAD_FEATURE_CONDITION_DATA = 'MAIN_LOAD_FEATURE_CONDITION_DATA';
export const MAIN_FEATURE_CONDITION_DATA_SUCCESS = 'MAIN_FEATURE_CONDITION_DATA_SUCCESS';

export const MAIN_LOAD_FEATURE_METADATA = 'MAIN_LOAD_FEATURE_METADATA';
export const MAIN_FEATURE_METADATA_SUCCESS = 'MAIN_FEATURE_METADATA_SUCCESS';

export const MAIN_SET_ATTRIBUTE = 'MAIN_SET_ATTRIBUTE';
export const MAIN_SET_CLASSIFICATION_METHOD = 'MAIN_SET_CLASSIFICATION_METHOD';
export const MAIN_SET_CLASSIFICATION = 'MAIN_SET_CLASSIFICATION';
export const MAIN_SET_CHOROPLETH_FILTER = 'MAIN_SET_CHOROPLETH_FILTER';
export const MAIN_SET_INDICATOR_FILTER = 'MAIN_SET_INDICATOR_FILTER';
export const MAIN_SET_INDICATOR_FILTER_SUCCESS = 'MAIN_SET_INDICATOR_FILTER_SUCCESS';
export const MAIN_SET_DEMOGRAPHICS_FILTER = 'MAIN_SET_DEMOGRAPHICS_FILTER';
export const MAIN_SET_DEMOGRAPHICS_FILTER_SUCCESS = 'MAIN_SET_DEMOGRAPHICS_FILTER_SUCCESS';

// rank
export const MAIN_GET_RANK_DATA = 'MAIN_GET_RANK_DATA';
export const MAIN_RANK_DATA_SUCCESS = 'MAIN_RANK_DATA_SUCCESS';

// login
export const MAIN_LOGIN = 'MAIN_LOGIN';
export const MAIN_LOGOUT = 'MAIN_LOGOUT';
export const MAIN_LOGIN_SUCCESS = 'MAIN_LOGIN_SUCCESS';
export const MAIN_LOGIN_ERROR = 'MAIN_LOGIN_ERROR';
export const MAIN_LOGOUT_SUCCESS = 'MAIN_LOGOUT_SUCCESS';
export const MAIN_GET_API_KEY = 'MAIN_GET_API_KEY';
export const MAIN_API_KEY_SUCCESS = 'MAIN_API_KEY_SUCCESS';

// saved views
export const MAIN_SAVE_VIEW = 'MAIN_SAVE_VIEW';
export const MAIN_GET_VIEWS = 'MAIN_GET_VIEWS';
export const MAIN_GET_VIEW_BY_ID = 'MAIN_GET_VIEW_BY_ID';
export const MAIN_RECEIVED_VIEWS = 'MAIN_RECEIVED_VIEWS';
export const MAIN_DELETE_VIEW = 'MAIN_DELETE_VIEW';
export const MAIN_LOAD_SAVED_VIEW = 'MAIN_LOAD_SAVED_VIEW';

// import
export const MAIN_UPLOAD_FILE = 'MAIN_UPLOAD_FILE';
export const MAIN_UPLOAD_ERROR = 'MAIN_UPLOAD_ERROR';
export const MAIN_UPLOAD_SUCCESS = 'MAIN_UPLOAD_SUCCESS';
export const MAIN_GET_LAYERS = 'MAIN_GET_LAYERS';
export const MAIN_LAYERS_SUCCESS = 'MAIN_LAYERS_SUCCESS';
export const MAIN_SAVE_LAYER = 'MAIN_SAVE_LAYER';
export const MAIN_SAVE_LAYER_SUCCESS = 'MAIN_SAVE_LAYER_SUCCESS';
export const MAIN_SAVE_LAYER_ERROR = 'MAIN_SAVE_LAYER_ERROR';
export const MAIN_DELETE_LAYER = 'MAIN_DELETE_LAYER';
export const MAIN_LOAD_LAYER_DATA = 'MAIN_LOAD_LAYER_DATA';
export const MAIN_ADD_OVERLAY = 'MAIN_ADD_OVERLAY';
export const MAIN_REMOVE_OVERLAY = 'MAIN_REMOVE_OVERLAY';
export const MAIN_SET_CURRENT_USER_LAYER = 'MAIN_SET_CURRENT_USER_LAYER';

// export
export const MAIN_EXPORT_MAP = 'MAIN_EXPORT_MAP';
export const EXPORT_MAP_SUCCESS = 'EXPORT_MAP_SUCCESS';

// policy opportunities
export const MAIN_GET_POLICIES = 'MAIN_GET_POLICIES';
export const MAIN_POLICIES_SUCCESS = 'MAIN_POLICIES_SUCCESS';
export const MAIN_SET_SELECTED_POLICY = 'MAIN_SET_SELECTED_POLICY';

export const MAIN_SET_CUSTOM_SCORE = 'MAIN_SET_CUSTOM_SCORE';
export const MAIN_CUSTOM_SCORE_SUCCESS = 'MAIN_CUSTOM_SCORE_SUCCESS';

export const MAIN_SET_MULTIPLE_VULNERABILITIES = 'MAIN_SET_MULTIPLE_VULNERABILITIES';
export const MAIN_MULTIPLE_VULNERABILITIES_SUCCESS = 'MAIN_MULTIPLE_VULNERABILITIES_SUCCESS';

export const MAIN_SET_ENV = 'MAIN_SET_ENV';
export const MAIN_SET_DEFAULT_PANEL = 'MAIN_SET_DEFAULT_PANEL';
