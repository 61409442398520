/*
************
*********
******
****

DO NOT EDIT THIS FILE

Internal logic to connect panel metadata to components. 

****
******
********
************
*/

import CommunityConditions from '@components/community-conditions/community-conditions-panel';
import CommunityConditionsHeat from '@components/community-conditions/community-conditions-panel-ucla';
import CommunityConditionsLearnMore from '@components/community-conditions/community-conditions-learn-more';
import CommunityConditionsUtahLearnMore from '@components/community-conditions/community-conditions-utah-learn-more';
import IndicatorsPanel from '@components/view-indicators/indicators-panel';
import IndicatorsPanelHeat from '@components/view-indicators/indicators-panel-ucla';
import IndicatorsLearnMore from '@components/view-indicators/indicators-learn-more';
import PoolPanel from '@components/pool-geographies/pool-panel';
import DisplayPanel from '@components/edit-display/display-panel';
import PoolLearnMore from '@components/pool-geographies/pool-learn-more';
import LifeExpectancyPanel from '@components/predict-life-expectancy/life-expectancy-panel';
import LifeExpectancyLearnMore from '@components/predict-life-expectancy/life-expectancy-learn-more';
import CompareIndicators from '@components/compare-indicators';
import CompareLearnMore from '@components/compare-indicators/compare-indicators-learn-more';
import UploadPanel from '@components/upload-data/upload-panel';
import CovidPanel from '@components/covid-panel';
import PoliciesPanel from '@components/policy-opportunities/policies-panel';
import PoliciesLearnMore from '@components/policy-opportunities/policies-learn-more';
import CustomScorePanel from '@components/custom-score/custom-score-panel';
import CustomScoreLearnMore from '@components/custom-score/custom-score-learn-more';
import RaceFilterPanel from '@components/race-filter/race-filter-panel';
import RaceFilterLearnMore from '@components/race-filter/race-filter-learn-more';
import {
  faInfo,
  faHeart,
  faCompress,
  faBinoculars,
  faPalette,
  faEquals,
  faSortAmountDown,
  faUpload,
  faVirus,
  faSlidersH,
  faBalanceScale,
  faPencil,
  faPollPeople,
  faTemperatureHot,
  faDownload,
  faHeat,
} from '@fortawesome/pro-regular-svg-icons';
import hpiLogoMini from '@client/assets/hpi-logo-mini.png';
import RankPanel from '@components/rank-by-geography/rank-panel';
import FilterPanel from '@components/filter/filter-panel';
import FilterPanelHeat from '@components/filter/filter-panel-ucla';
import FilterLearnMore from '@components/filter/filter-learn-more';
import RankLearnMore from '@components/rank-by-geography/rank-learn-more';
import MultipleVulnerabilitiesPanel from '@components/multiple-vulnerabilities';
import HeatResourcesPanel from '@components/heat-resources';
import HeatResourcesLearnMore from '@components/heat-resources/heat-resources-learn-more';
import { panelData } from '@client/config/config';
import MapLegend from '@client/components/map-components/map-legend';
import MapLegendHeat from '@client/components/map-components/map-legend-ucla';
import { omit } from 'lodash';

// more metadata about each panel
const allPanels = [
  {
    key: 'community-conditions',
    view: 'community-conditions',
    componentVariants: { default: CommunityConditions },
    learnMoreComponentVariants: {
      default: CommunityConditionsLearnMore,
      utah: CommunityConditionsUtahLearnMore,
    },
    icon: faInfo,
    mapVisible: true, // need to be able to see/click map with panel open (e.g. when showing "click a tract" prompt)
  },
  {
    key: 'climate',
    view: 'climate',
    componentVariants: { default: CommunityConditionsHeat },
    learnMoreComponentVariants: { default: CommunityConditionsLearnMore },
    icon: faInfo,
  },
  {
    key: 'multiple-vulnerabilities',
    view: 'multiple-vulnerabilities',
    componentVariants: { default: MultipleVulnerabilitiesPanel },
    icon: faPencil,
  },
  {
    key: 'heat-resources',
    view: 'heat-resources',
    componentVariants: { default: HeatResourcesPanel },
    learnMoreComponentVariants: { default: HeatResourcesLearnMore },
    icon: faHeat,
  },
  {
    key: 'filter',
    view: 'filter',
    componentVariants: { default: FilterPanel, heat: FilterPanelHeat },
    learnMoreComponentVariants: { default: FilterLearnMore },
    icon: faSlidersH,
  },
  {
    key: 'demographics-filter',
    view: 'demographics-filter',
    componentVariants: { default: RaceFilterPanel },
    learnMoreComponentVariants: { default: RaceFilterLearnMore },
    icon: faPollPeople,
  },
  {
    key: 'view-indicators',
    view: 'view-indicators',
    componentVariants: { default: IndicatorsPanel, heat: IndicatorsPanelHeat },
    learnMoreComponentVariants: { default: IndicatorsLearnMore },
    icon: faBinoculars,
  },
  {
    key: 'predict-life-expectancy',
    view: 'predict-life-expectancy',
    componentVariants: { default: LifeExpectancyPanel },
    learnMoreComponentVariants: { default: LifeExpectancyLearnMore },
    icon: faHeart,
    mapVisible: true,
  },
  {
    key: 'pool-geographies',
    view: 'pool-geographies',
    componentVariants: { default: PoolPanel },
    learnMoreComponentVariants: { default: PoolLearnMore },
    icon: faCompress,
    mapVisible: true,
  },
  {
    key: 'edit-display',
    view: 'edit-display',
    componentVariants: { default: DisplayPanel },
    icon: faPalette,
  },
  {
    key: 'compare-indicators',
    view: 'compare-indicators',
    componentVariants: { default: CompareIndicators },
    learnMoreComponentVariants: { default: CompareLearnMore },
    icon: faEquals,
  },
  {
    key: 'rank-by-geography',
    view: 'rank-by-geography',
    componentVariants: { default: RankPanel },
    learnMoreComponentVariants: { default: RankLearnMore },
    icon: faSortAmountDown,
  },
  {
    key: 'upload-data',
    view: 'upload-data',
    componentVariants: { default: UploadPanel },
    icon: faUpload,
  },
  {
    key: 'policy-opportunities',
    view: 'policy-opportunities',
    componentVariants: { default: PoliciesPanel },
    learnMoreComponentVariants: { default: PoliciesLearnMore },
    icon: faBalanceScale,
  },

  {
    key: 'custom-score',
    view: 'custom-score',
    componentVariants: { default: CustomScorePanel },
    learnMoreComponentVariants: { default: CustomScoreLearnMore },
    icon: faPencil,
  },
  {
    key: 'heat',
    componentVariants: {},
    icon: faTemperatureHot,
  },
  {
    key: 'hpi2',
    componentVariants: {},
    icon: hpiLogoMini,
  },
  {
    key: 'hpi3',
    componentVariants: {},
    icon: hpiLogoMini,
  },
  {
    key: 'covid',
    view: 'covid',
    componentVariants: { default: CovidPanel },
    icon: faVirus,
  },
  {
    key: 'account',
    componentVariants: {},
    icon: faDownload,
  },
];

// combine the "public" config settings with the internal stuff above
const panels = allPanels.reduce((combined, panel) => {
  const config = panelData.find(p => panel.key === p.key);
  if (config) {
    return [
      ...combined,
      {
        ...panel,
        ...config,
      },
    ];
  }
  return [...combined];
}, []);

export {
  defaultGeographyName,
  defaultIndicatorName,
  defaultClassification,
} from '@client/config/config';

export const getMapLegend = componentSet => {
  if (componentSet === 'heat') return MapLegendHeat;
  return MapLegend;
};

export const getPanels = env => {
  const { componentSet } = env;
  return panels.map(p => {
    return {
      ...omit(p, 'componentVariants', 'learnMoreComponentVariants'),
      description: p.description.replace(/\{(.+?)\}/g, (match, varName) => env[varName]),
      component: p.componentVariants[componentSet] || p.componentVariants.default,
      learnMoreComponent: p.learnMoreComponentVariants
        ? p.learnMoreComponentVariants[componentSet] || p.learnMoreComponentVariants.default
        : undefined,
    };
  });
};

export default panels;
