import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from '@ducks/routes';
import Loadable from 'react-loadable';
import notFoundRoute from '@components/shared/notFound/notFound.route';
import './style.scss';

const LoadableComponent = loader =>
  Loadable({
    loader,
    loading: () => null,
  });

/**
 * Generate Route components for all routes
 */
const generateRoutesComponent = () => {
  const components = Object.values(routes).map(route => (
    <Route
      key={route.path}
      exact
      path={route.path}
      component={LoadableComponent(route.component)}
    />
  ));
  // Add not found route
  components.push(<Route key="notFound" component={LoadableComponent(notFoundRoute.component)} />);
  return components;
};

class App extends React.Component {
  componentDidMount = () => {
    const loadingElement = document.getElementById('ipl-progress-indicator');
    if (loadingElement) {
      loadingElement.classList.add('available');
      setTimeout(() => {
        loadingElement.outerHTML = '';
      }, 2000);
    }
  };

  render = () => {
    return (
      <div className="App">
        <Switch>{generateRoutesComponent()}</Switch>
      </div>
    );
  };
}

export default App;
