import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import * as types from './main.actionType';
import {
  defaultClassification,
  defaultGeographyName,
  defaultIndicatorName,
} from '@client/config/components/panels';
import {
  divergingColors,
  heatColors,
  percentileColors,
  sequentialColors,
  valueColors,
  vulnerabilityColors,
} from '@client/config/config';
import { intersection } from 'd3-array';
import { format, max } from 'd3';
import { omit, uniq } from 'lodash';

const persistConfig = {
  key: 'main',
  storage,
  whitelist: [
    /* keys to be persisted */
  ],
};

const initIndicator = {
  name: 'hpi',
  id: 1,
  mapPercentile: true,
  domain: { type: ['hpi'], name: 'Primary' },
};
const percentileClassification = [
  { min: 0, max: 0.25, color: percentileColors[0] },
  { min: 0.25, max: 0.5, color: percentileColors[1] },
  { min: 0.5, max: 0.75, color: percentileColors[2] },
  { min: 0.75, max: 1, color: percentileColors[3] },
];
const valueClassification = [
  { ...percentileClassification[0], color: valueColors[0] },
  { ...percentileClassification[1], color: valueColors[1] },
  { ...percentileClassification[2], color: valueColors[2] },
  { ...percentileClassification[3], color: valueColors[3] },
];
const climateClassification = [
  { ...percentileClassification[0], color: heatColors[0] },
  { ...percentileClassification[1], color: heatColors[1] },
  { ...percentileClassification[2], color: heatColors[2] },
  { ...percentileClassification[3], color: heatColors[3] },
];
const hpiClassification = [
  { ...percentileClassification[0], color: divergingColors[0] },
  { ...percentileClassification[1], color: divergingColors[1] },
  { ...percentileClassification[2], color: divergingColors[2] },
  { ...percentileClassification[3], color: divergingColors[3] },
];

const set1Colors = ['#b30178', '#ffff33', '#a65628', '#f781bf', '#999999', '#000000', '#ffffff'];
const initialState = {
  loading: false,
  // geography
  geographiesList: [],
  currentGeography: {
    name: 'N/A',
    singular: 'N/A',
    id: 11,
    layer: 'na',
    tileUrl:
      typeof window !== 'undefined' && window.env
        ? `${window.env.CLIENT_TILE_CACHE}/tracts/{z}/{x}/{y}.pbf`
        : 'https://tilecache.axismaps.io/hpi/tracts/{z}/{x}/{y}.pbf',
  },
  // indicator
  indicatorList: [],
  allIndicators: [], // flat array
  defaultIndicator: initIndicator,
  primaryIndicator: initIndicator,
  currentIndicator: initIndicator,
  currentIndicator2: null, // for split screen
  allIndicatorData: {},
  allPoolData: {},
  currentIndicatorData: [],
  currentPoolData: [],
  currentIndicatorData2: [], // for split screen
  currentPoolData2: [], // for split screen
  // choropleth
  attribute: 'percentile',
  attribute2: 'percentile',
  classificationMethod: defaultClassification || 'Quartiles',
  classificationMethod2: defaultClassification || 'Quartiles',
  defaultClassification,
  sequentialColors, // default colors
  divergingColors, // default colors
  percentileClassification,
  vulnerabilityColors,
  valueClassification,
  climateClassification,
  hpiClassification,
  choroplethClassification: hpiClassification,
  choroplethClassification2: percentileClassification, // for split screen
  choroplethFilter: [0, 100], // filter by current indicator
  indicatorFilter: {}, // filter by other indicators
  demographicsFilter: {
    races: [],
    threshold: 100,
    attribute: 'count',
  }, // filter by other indicators
  // highlight/selection
  highlightedFeature: null,
  selectedFeature: null,
  selectedAddress: null,
  // ui views
  currentView: null,
  viewOptions: null,
  toolsMenuActive: false,
  activeChanges: [],
  // feature data
  featureConditionData: {},
  featureMetadata: {},
  // pools
  pools: [],
  activePool: {},
  inactivePools: {},
  // search,
  searchTerm: '',
  searchResults: null,
  // rank
  rankData: null,
  // user
  isLoggedIn: false,
  username: null,
  loginError: null,
  // saved views
  savedViews: [],
  // import
  layers: [],
  currentLayerImport: null,
  importError: null,
  importLoading: false,
  currentUserLayer: null, // csv user layer
  currentOverlays: [], // shapefile user layers
  // policies panel
  policyOpportunities: [],
  defaultPolicies: undefined,
  selectedPolicy: undefined,
  // custom score
  customScore: {
    domains: [],
    indicators: [],
  },
  multipleVulnerabilities: [],
  env: {
    appTitle: 'California Healthy Places Index',
    topGeography: 'California',
    maxBounds: [
      [-124.409591, 32.534156],
      [-114.131211, 42.009518],
    ],
    mapboxApiAccessToken: '',
    componentSet: 'hpi',
  },
  defaultView: 'community-conditions',
  defaultIndicatorView: 'view-indicators',
};

const getOverlayColor = currentOverlays => {
  const currentColors = currentOverlays.map(o => o.color);
  const unusedColors = set1Colors.filter(c => !currentColors.includes(c));
  if (!unusedColors.length) return set1Colors[currentOverlays.length % set1Colors.length];
  return unusedColors[0];
};

const getColorScheme = (indicator, classificationMethod, componentSet) => {
  // if (indicator === defaultIndicator.id) return heatColors;
  if (indicator.parentIndicator) return valueColors;
  if (
    indicator.domain.name === 'Exposure' ||
    (indicator.domain.name === 'Primary' && componentSet === 'heat')
  )
    return heatColors;
  if (indicator.domain.name === 'County COVID-19 Counts') return percentileColors;
  // if (indicator.parentIndicator) return valueColors; // purple scheme for stratified indicators
  if (indicator.negative && !indicator.domain.type.includes('hpi')) return valueColors;
  if (indicator.domain.type.includes('hpi') && classificationMethod.toLowerCase() === 'quartiles')
    return divergingColors;

  // catch HPI score indicator in heat edition
  if (componentSet === 'heat' && indicator.mapPercentile) return divergingColors;

  if (indicator.classification && indicator.classification.length === 2) {
    return valueColors.map((c, i) => {
      if (i === 1) return valueColors[valueColors.length - 1];
      return c;
    });
  }
  return valueColors;
};

const addIndicatorData = (currentData, geographies, year = 'all') => {
  if (!geographies) return currentData; // not adding data; this happens when re-loading a geography/indicator
  // appends incoming indicator data
  const dataCopy = { ...currentData };
  Object.entries(geographies).forEach(([, newData]) => {
    const { geography, indicator, data } = newData;
    if (!dataCopy[geography]) dataCopy[geography] = {};
    if (!dataCopy[geography][indicator]) dataCopy[geography][indicator] = {};
    if (!dataCopy[geography][indicator][year]) dataCopy[geography][indicator][year] = [];
    if (data) {
      const dataArray = [...data];
      // force percentile to be defined on data of length 1 (e.g. a single county dataset), except pools
      if (dataArray.length === 1 && !dataArray[0].geoids && !dataArray[0].pool)
        dataArray[0].percentile = 1;
      dataCopy[geography][indicator][year] = [
        ...dataCopy[geography][indicator][year],
        ...dataArray,
      ];
    }
  });

  return dataCopy;
};

const getIndicator = (id, indicatorList, layers) => {
  const flatIndicators = indicatorList.reduce(
    (flatArray, group) => [...flatArray, ...group.Indicators],
    []
  );
  const flatStratififcations = flatIndicators.reduce((flatStrats, ind) => {
    if (!ind.Stratifications) return flatStrats;
    return [
      ...flatStrats,
      ...ind.Stratifications.reduce((allSlices, strat) => [...allSlices, ...strat.Slices], []),
    ];
  }, []);
  let currentIndicator = [...flatIndicators, ...flatStratififcations].find(
    i => i.id === id || i.IndicatorId === id
  );
  if (!currentIndicator) currentIndicator = layers.find(l => l.id === id);
  return currentIndicator; // undefined if not found in either list
};

const search = (searchTerm, geographies) => {
  if (!searchTerm || searchTerm.length < 2) return null;
  const reg = new RegExp(searchTerm.trim(), 'gi');
  return geographies
    .filter(g => g.features)
    .map(g => ({
      ...g,
      results: g.features
        .filter(f => f.name && f.name.match(reg))
        .sort((a, b) => {
          if (a.name.search(reg) < b.name.search(reg)) return -1;
          if (b.name.search(reg) < a.name.search(reg)) return 1;
          return a.name.localeCompare(b.name);
        }),
    }))
    .filter(g => g.results.length > 0);
};

/*
 * containers/main/reducer.js : main reducer
 * @param {Object} state the state of the main container
 * @param {Object} action the redux action instance
 * @returns {Object} returns the new state
 */
function main(state = initialState, action) {
  // NOSONAR
  // console.log(action);
  switch (action.type) {
    case types.MAIN_SET_DEFAULT_PANEL: {
      return {
        ...state,
        defaultView: action.payload.defaultIndicatorSelected,
        defaultIndicatorView: action.payload.otherIndicatorSelected,
      };
    }
    /**
     * Data loading */

    case types.MAIN_LOAD_INDICATOR_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_LOAD_INDICATOR_LIST_SUCCESS: {
      const { env } = state;
      const indicatorList = action.payload.response.map(cat => {
        let domainType = [];
        if (cat.Types) domainType = cat.Types;
        if (cat.name === 'Primary') domainType = ['hpi'];
        return {
          ...cat,
          type: domainType,
          Indicators: cat.Indicators.map(ind => {
            const { percentile, unit, type, Stratifications, ...props } = ind;
            // TO DO: get the real type from database

            let formattedUnit = unit;
            if (
              formattedUnit &&
              formattedUnit !== '%' &&
              formattedUnit !== '$' &&
              formattedUnit[0] !== ' '
            ) {
              formattedUnit = ` ${formattedUnit}`;
            }
            const newProps = {
              domain: {
                id: cat.id,
                name: cat.name,
                weight: cat.weight,
                type: domainType,
              },
              unit: formattedUnit,
              isPercent: ind.unit && ind.unit[0] === '%',
              mapPercentile: percentile,
              colors: getColorScheme(
                { ...ind, domain: { ...cat, type: domainType }, mapPercentile: percentile },
                'quartiles',
                env.componentSet
              ),
              label: undefined,
              formatValue: (value, omitUnit, shortenLargeNumbers = false) => {
                if (value === undefined || value === null) return 'N/A';
                const val = ind.unit && ind.unit[0] === '%' ? value * 100 : value;
                const formatter = val > 5000 && shortenLargeNumbers ? 's' : 'r'; // use SI suffix for larger numbers
                // 3 significant digits can be too long for small numbers
                const formatted = format(`,.3${formatter}`)(val);
                // let decimalIndex = formatted.indexOf('.');
                // if (decimalIndex > -1 && formatted.length - decimalIndex > 4) {
                //   const label2 = format(`,.2${formatter}`)(val);
                //   decimalIndex = label2.indexOf('.');
                //   if (decimalIndex > -1 && label2.length - decimalIndex > 4 && val > 1) {
                //     // if 2 sig digits is still too long, just round to integer
                //     formatted = format('.3s')(val);
                //   }
                //   formatted = label2;
                // }
                if (omitUnit) return formatted;
                if (formattedUnit === '$') return `${formattedUnit}${formatted}`;
                return `${formatted}${formattedUnit}`;
              },
              getYearDisplay: year => {
                let displayValue;
                if (cat.name === 'Primary') {
                  if (ind.dates) {
                    if (year === Math.max(...ind.dates)) {
                      displayValue = env.componentSet === 'utah' ? 'v2.0' : 'v3.0';
                    } else {
                      displayValue = env.componentSet === 'utah' ? 'v1.0' : 'v2.0';
                    }
                  }
                } else if (!year || year === 'all') {
                  if (ind.datesDisplay) {
                    displayValue = ind.datesDisplay[ind.datesDisplay.length - 1];
                  } else displayValue = '';
                } else if (ind.datesDisplay && ind.dates) {
                  displayValue = ind.datesDisplay[ind.dates.indexOf(year)] || ind.datesDisplay[0];
                }
                return displayValue || year;
              },
              childDomain: action.payload.response.find(
                domain => domain.parent && domain.parent.id === ind.id
              ),
            };
            let stratifications;
            if (Stratifications) {
              stratifications = Stratifications.map(strat => {
                return {
                  ...strat,
                  Slices: strat.Slices.reduce((reduced, slice) => {
                    // remove duplicates
                    if (!reduced.find(s => s.IndicatorId === slice.IndicatorId)) {
                      return [
                        ...reduced,
                        {
                          ...slice,
                          ...newProps,
                          id: slice.IndicatorId,
                          Geographies: slice.geographies,
                          ...omit(ind, 'Stratifications', 'id', 'Geographies', 'title', 'dates'), // include most metadata of parent indicator
                          year: slice.dates ? max(slice.dates, d => +d) : 'all', // will indicate currently selected year
                          year2: slice.dates ? max(slice.dates, d => +d) : 'all', // same as above but for map 2 in compare mode
                          latestYear: slice.dates ? max(slice.dates, d => +d) : 'all',
                          title: `${ind.title}: ${slice.name}`,
                          parentIndicator: omit(ind, 'Stratifications'), // add reference to parent indicator
                        },
                      ];
                    }
                    return [...reduced];
                  }, []),
                };
              });
            }
            return {
              ...props,
              ...newProps,
              year: ind.dates ? max(ind.dates, d => +d) : 'all', // will indicate currently selected year
              year2: ind.dates ? max(ind.dates, d => +d) : 'all', // same as above but for map 2 in compare mode
              latestYear: ind.dates ? max(ind.dates, d => +d) : 'all',
              Stratifications: stratifications,
            };
          }),
        };
      });
      const indicator =
        indicatorList
          .reduce((flat, group) => [...flat, ...group.Indicators], [])
          .find(i => i.title === defaultIndicatorName || i.default) ||
        (indicatorList.find(g => g.name === 'Primary') || indicatorList[0]).Indicators[0];
      const flatIndicators = indicatorList.reduce(
        (flatArray, group) => [...flatArray, ...group.Indicators],
        []
      );
      const flatStratififcations = flatIndicators.reduce((flatStrats, ind) => {
        if (!ind.Stratifications) return flatStrats;
        return [
          ...flatStrats,
          ...ind.Stratifications.reduce((allSlices, strat) => [...allSlices, ...strat.Slices], []),
        ];
      }, []);
      return {
        ...state,
        loading: false,
        indicatorList,
        allIndicators: [...flatIndicators, ...flatStratififcations],
        defaultIndicator: indicator,
        currentIndicator: indicator,
        primaryIndicator: (
          action.payload.response.find(g => g.name === 'Primary') || action.payload.response[0]
        ).Indicators[0],
        customScore: {
          domains: [],
          indicators: indicatorList
            .filter(d => d.weight > 0)
            .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
            .map(i => i.id),
        },
      };
    }

    case types.MAIN_LOAD_GEOGRAPHIES_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        geographiesList: action.payload.response,
        currentGeography:
          action.payload.response.find(i => i.name === defaultGeographyName) ||
          action.payload.response.find(g => g.default === true),
        allIndicatorData: action.payload.response.reduce(
          (obj, geog) => ({ ...obj, [geog.id]: {} }),
          {}
        ),
      };
    }

    case types.MAIN_GEOGRAPHIES_STATS: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_GEOGRAPHIES_STATS_SUCCESS: {
      const { stats, geography } = action.payload;
      return {
        ...state,
        loading: false,
        geographiesList: state.geographiesList.map(g => {
          if (geography === g.id) {
            return {
              ...g,
              stats,
            };
          }
          return g;
        }),
        indicatorList: state.indicatorList.map(domain => {
          return {
            ...domain,
            Indicators: domain.Indicators.map(ind => {
              const indicatorStats = stats.find(s => s.id === ind.id);
              return {
                ...ind,
                stats: {
                  ...(ind.stats || {}),
                  [geography]: indicatorStats,
                },
              };
            }),
          };
        }),
        currentGeography: { ...state.currentGeography, stats },
      };
    }

    case types.MAIN_LOAD_INDICATOR_DATA:
      return {
        ...state,
        loading: true,
      };

    case types.MAIN_LOAD_INDICATOR_DATA_SUCCESS: {
      // this will happen when clearing split screen map, because I don't know how to "do nothing" w/ Redux observable
      if (!action.payload) return state;

      const { which, dataOnly, year } = action.payload; // dataOnly = do not set this as current indicator
      const { defaultIndicator } = state;

      if (!action.payload.isPools) {
        let whichCurrentIndicatorData = 'currentIndicatorData';
        let whichCurrentIndicator = 'currentIndicator';
        let whichChoroplethClassification = 'choroplethClassification';
        if (which === 2) {
          whichCurrentIndicatorData = 'currentIndicatorData2';
          whichCurrentIndicator = 'currentIndicator2';
          whichChoroplethClassification = 'choroplethClassification2';
        }
        const newData = addIndicatorData(
          state.allIndicatorData,
          action.payload.geographies, // <- contains one or more data objects, i.e. current geography and/or state level
          action.payload.year
        );
        const { rankData } = state;
        // rescaled ranks take priority over regular data
        const newCurrentIndicatorData =
          rankData && rankData.data && rankData.data.rescale
            ? rankData.ranks
            : newData[action.payload.geography][action.payload.indicator][year || 'all'];
        let choroplethClassification = state[whichChoroplethClassification];
        let indicator = getIndicator(action.payload.indicator, state.indicatorList, state.layers);
        if (indicator && indicator.layerId) {
          // use value color scheme for user csv layers
          choroplethClassification = [
            { ...choroplethClassification[0], color: valueColors[0] },
            { ...choroplethClassification[1], color: valueColors[1] },
            { ...choroplethClassification[2], color: valueColors[2] },
            { ...choroplethClassification[3], color: valueColors[3] },
          ];
          indicator = {
            ...indicator,
            colors: valueColors,
          };
        }
        if (!dataOnly) {
          return {
            ...state,
            loading: false,
            allIndicatorData: newData,
            [whichCurrentIndicatorData]: newCurrentIndicatorData,
            [whichCurrentIndicator]: indicator || defaultIndicator,
            currentGeography: state.geographiesList.find(g => g.id === action.payload.geography),
            // reset to blue-green colors if this is default HPI indicator
            [whichChoroplethClassification]:
              action.payload.indicator === defaultIndicator.id && defaultIndicator.mapPercentile
                ? [
                    { ...choroplethClassification[0], color: defaultIndicator.colors[0] },
                    { ...choroplethClassification[1], color: defaultIndicator.colors[1] },
                    { ...choroplethClassification[2], color: defaultIndicator.colors[2] },
                    { ...choroplethClassification[3], color: defaultIndicator.colors[3] },
                  ]
                : [
                    { ...choroplethClassification[0], color: indicator.colors[0] },
                    { ...choroplethClassification[1], color: indicator.colors[1] },
                    { ...choroplethClassification[2], color: indicator.colors[2] },
                    { ...choroplethClassification[3], color: indicator.colors[3] },
                  ],
          };
        }
        return {
          ...state,
          loading: false,
          allIndicatorData: newData,
        };
      }
      const newData = addIndicatorData(state.allPoolData, action.payload.geographies);
      const whichCurrentPoolData = which === 2 ? 'currentPoolData2' : 'currentPoolData';
      return {
        ...state,
        loading: false,
        allPoolData: newData,
        [whichCurrentPoolData]: newData[action.payload.geography][action.payload.indicator].all,
      };
    }

    case types.MAIN_HISTOGRAM_DATA_SUCCESS: {
      const indicators = [...state.indicatorList];
      const indicator = getIndicator(action.payload.indicator, state.indicatorList, state.layers);
      if (!indicator.histograms) indicator.histograms = {};
      indicator.histograms[action.payload.geography] = action.payload.data;
      return {
        ...state,
        indicatorList: indicators,
        currentIndicator:
          state.currentIndicator && state.currentIndicator.id === action.payload.indicator
            ? indicator
            : state.currentIndicator,
      };
    }

    case types.MAIN_SET_INDICATOR: {
      // note that this action does not happen for user csv layers
      const { indicator, which, year } = action.payload;
      const {
        defaultIndicator,
        choroplethClassification,
        currentView,
        selectedFeature,
        defaultView,
      } = state;
      // clear split screen
      if (which === 2 && indicator === null) {
        return {
          ...state,
          currentIndicator2: null,
          currentIndicatorData2: [],
          currentPoolData2: [],
          classificationMethod2: state.classificationMethod,
        };
      }
      const id = indicator || defaultIndicator.id;
      const whichCurrentIndicator = which === 2 ? 'currentIndicator2' : 'currentIndicator';
      const whichChoroplethClassification =
        which === 2 ? 'choroplethClassification2' : 'choroplethClassification';
      const whichClassificationMethod =
        which === 2 ? 'classificationMethod2' : 'classificationMethod';
      const whichYear = which === 2 ? 'year2' : 'year';
      const currentIndicator =
        getIndicator(id, state.indicatorList, state.layers) || defaultIndicator;
      const { domain } = currentIndicator;
      const newClassification = currentIndicator.mapPercentile ? 'Quartiles' : 'Equal Interval';
      let classification;
      const colorScheme = getColorScheme(currentIndicator, newClassification);
      if (!currentIndicator.mapPercentile) {
        classification = [
          { ...choroplethClassification[0], color: colorScheme[0] },
          { ...choroplethClassification[1], color: colorScheme[1] },
          { ...choroplethClassification[2], color: colorScheme[2] },
          { ...choroplethClassification[3], color: colorScheme[3] },
        ];
      } else if (domain.name === 'County COVID-19 Counts') {
        classification = [
          { min: 0, max: 0.25, color: colorScheme[3] },
          { min: 0.25, max: 0.5, color: colorScheme[2] },
          { min: 0.5, max: 0.75, color: colorScheme[1] },
          { min: 0.75, max: 1, color: colorScheme[0] },
        ];
      } else {
        classification = [
          { min: 0, max: 0.25, color: colorScheme[0] },
          { min: 0.25, max: 0.5, color: colorScheme[1] },
          { min: 0.5, max: 0.75, color: colorScheme[2] },
          { min: 0.75, max: 1, color: colorScheme[3] },
        ];
      }
      // clear currentUserLayer if switching away from a csv layer here
      const currentUserLayer =
        state.currentUserLayer && state.currentUserLayer.type === 'csv' && !currentIndicator.layerId
          ? null
          : state.currentUserLayer;

      if (currentIndicator.dates) {
        if (year) {
          currentIndicator[whichYear] = year;
        } else {
          // set to most recent year, if not specified
          currentIndicator[whichYear] = max(currentIndicator.dates, d => +d);
        }
      } else {
        currentIndicator.year = 'all';
        currentIndicator.year2 = 'all';
      }
      let view = currentView;
      if (selectedFeature && id === defaultIndicator.id && currentView === 'view-indicators') {
        view = defaultView;
      }

      return {
        ...state,
        customScore: {
          domains: [],
          indicators: state.indicatorList
            .filter(d => d.weight > 0)
            .reduce((flat, d) => [...flat, ...d.Indicators], [])
            .map(i => i.id),
        },
        activeChanges: state.activeChanges.filter(
          c => c.key !== 'custom-score' && c.key !== 'edit-display'
        ),
        [whichClassificationMethod]: newClassification,
        [whichChoroplethClassification]: classification,
        [whichCurrentIndicator]: currentIndicator,
        currentUserLayer,
        currentView: view,
        // indicatorFilter: {},
      };
    }

    case types.MAIN_SET_GEOGRAPHY: {
      const newGeog = state.geographiesList.find(g => g.id === action.payload);
      const newRankData =
        state.rankData && action.payload !== state.rankData.data.geography ? null : state.rankData;
      const inactivePools = { ...state.inactivePools };
      if (state.currentGeography.id !== action.payload && state.pools.length) {
        inactivePools[state.currentGeography.id] = [...state.pools];
      }

      return {
        ...state,
        currentGeography: newGeog,
        // deselect feature if switching geography
        selectedFeature: newGeog.id === state.currentGeography.id ? state.selectedFeature : null,
        activePool: {},
        inactivePools,
        pools: [...(state.inactivePools[newGeog.id] || [])],
        rankData: newRankData,
        demographicsFilter: {
          ...state.demographicsFilter,
          // races: [],
          geoids: [],
        },
        activeChanges: state.activeChanges.filter(c => c.key !== 'demographics-filter'),
        //  indicatorFilter: {},
      };
    }
    /**
     * Highlight/select feature */

    case types.MAIN_HIGHLIGHT_FEATURE: {
      return {
        ...state,
        highlightedFeature: action.payload,
      };
    }

    case types.MAIN_SELECT_FEATURE: {
      const { defaultIndicator, defaultView, defaultIndicatorView, currentIndicatorData } = state;
      let view = state.currentView;
      // open details panel on feature selection, unless certain panels are open
      const viewsNotToChange = ['predict-life-expectancy', 'policy-opportunities']; // and others?

      if (action.payload !== null && !viewsNotToChange.includes(view)) {
        if (state.currentIndicatorData2 && state.currentIndicatorData2.length) {
          // open compare panel if in split screen
          view = 'compare-indicators';
        }
        if (
          action.payload.searched &&
          currentIndicatorData.every(d => d.geoid !== action.payload.geoid)
        ) {
          view = '';
        } else {
          view =
            state.currentIndicator.id === defaultIndicator.id ||
            state.currentIndicator.title === 'Custom Score' ||
            state.multipleVulnerabilities.length > 0
              ? defaultView
              : defaultIndicatorView;
        }
      }

      return {
        ...state,
        selectedFeature: action.payload,
        currentView: view,
        selectedAddress: null,
      };
    }

    case types.MAIN_SELECT_ADDRESS: {
      return {
        ...state,
        selectedAddress: action.payload,
      };
    }

    /**
     * Map classification and filter */

    case types.MAIN_SET_ATTRIBUTE: {
      const { attribute, which } = action.payload;
      const whichAttribute = which === 2 ? 'attribute2' : 'attribute';
      return {
        ...state,
        [whichAttribute]: attribute,
      };
    }

    case types.MAIN_SET_CLASSIFICATION_METHOD: {
      const { method, which } = action.payload;
      const whichClassificationMethod =
        which === 2 ? 'classificationMethod2' : 'classificationMethod';
      return {
        ...state,
        [whichClassificationMethod]: method,
      };
    }

    case types.MAIN_SET_CLASSIFICATION: {
      const { classification, which } = action.payload;
      const whichChoroplethClassification =
        which === 2 ? 'choroplethClassification2' : 'choroplethClassification';
      return {
        ...state,
        [whichChoroplethClassification]: classification,
      };
    }

    case types.MAIN_SET_CHOROPLETH_FILTER: {
      const { choroplethClassification, currentIndicator } = state;
      return {
        ...state,
        choroplethFilter:
          action.payload ||
          (currentIndicator.classification && currentIndicator.classification.length
            ? currentIndicator.classification.map(c => c.color)
            : [
                choroplethClassification[0].min,
                choroplethClassification[choroplethClassification.length - 1].max,
              ]),
      };
    }

    case types.MAIN_SET_INDICATOR_FILTER: {
      // action only does anything if removing all filters, otherwise gets kicked to 'success' action below
      if (!action.payload) {
        return {
          ...state,
          indicatorFilter: {},
        };
      }
      return {
        ...state,
        loading: true, // setting filter may involve loading an indicator
      };
    }

    case types.MAIN_SET_INDICATOR_FILTER_SUCCESS: {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      const { indicatorFilter } = state;
      const { indicator, values } = action.payload;
      const filterCopy = { ...indicatorFilter };
      if (!values && filterCopy[indicator]) {
        delete filterCopy[indicator];
      } else if (values) {
        filterCopy[indicator] = action.payload;
      }
      return {
        ...state,
        indicatorFilter: filterCopy,
        loading: false,
      };
    }

    case types.MAIN_SET_DEMOGRAPHICS_FILTER: {
      // action only does anything if removing all filters, otherwise gets kicked to 'success' action below
      if (!action.payload) {
        return {
          ...state,
          demographicsFilter: {
            races: [],
            threshold: 100,
            geoids: [],
            attribute: 'count',
          },
        };
      }
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_SET_DEMOGRAPHICS_FILTER_SUCCESS: {
      if (!action.payload) {
        return {
          ...state,
          demographicsFilter: {
            races: [],
            threshold: 100,
            geoids: [],
            attribute: 'count',
          },
          loading: false,
        };
      }
      return {
        ...state,
        demographicsFilter: action.payload,
        loading: false,
      };
    }

    /**
     * Probe */

    case types.MAIN_SET_PROBE_DATA: {
      return {
        ...state,
        probeData: action.payload,
      };
    }

    /**
     * Search */

    case types.MAIN_SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
        searchResults: search(action.payload, state.geographiesList),
      };
    }

    case types.MAIN_SET_ADDRESS_SEARCH_RESULTS: {
      if (action.payload && action.payload.searchTerm !== state.searchTerm) {
        // ignore if search term changed while this was loading
        return state;
      }
      if (!state.searchTerm) {
        return {
          ...state,
          searchResults: null,
        };
      }
      const results = action.payload ? action.payload.results : [];
      const currentResults = state.searchResults || [];
      let message;
      if (!results.length && action.payload && action.payload.searchTerm.length < 3) {
        message = 'To search addresses, enter more text.';
      } else if (!results.length) {
        message = 'No address results found.';
      }
      return {
        ...state,
        searchResults: [
          {
            name: 'Addresses',
            id: 'addresses',
            results,
            message,
          },
          ...currentResults.filter(r => r.id !== 'addresses'),
        ],
      };
    }

    /**
     * Active panel */

    case types.MAIN_SET_VIEW: {
      if (!action.payload) {
        return {
          ...state,
          currentView: null,
          viewOptions: null,
          toolsMenuActive: false,
          selectedFeature: null,
          selectedPolicy: null,
        };
      }

      return {
        ...state,
        currentView: action.payload.view,
        viewOptions: action.payload.initOptions,
        toolsMenuActive: false,
        selectedFeature: state.selectedFeature,
        selectedPolicy:
          action.payload.view === 'policy-opportunities' ? state.selectedPolicy : null,
      };
    }

    case types.MAIN_TOGGLE_TOOLS: {
      return {
        ...state,
        toolsMenuActive: action.payload,
      };
    }

    case types.MAIN_SET_PANEL_CHANGED: {
      const { activeChanges } = state;
      const currentChangeState = activeChanges.find(p => p.key === action.payload.panel.key);
      if (action.payload.changed) {
        if (currentChangeState) return state;
        return {
          ...state,
          activeChanges: [
            ...activeChanges,
            { ...action.payload.panel, onClear: action.payload.onClear },
          ],
        };
      }
      if (!currentChangeState) return state;
      return {
        ...state,
        activeChanges: activeChanges.filter(p => p.key !== currentChangeState.key),
      };
    }

    /**
     * Feature data */

    case types.MAIN_FEATURE_CONDITION_DATA_SUCCESS: {
      if (!action.payload.data) return state;
      return {
        ...state,
        featureConditionData: {
          ...state.featureConditionData,
          [action.payload.geoid]: action.payload.data,
        },
      };
    }

    case types.MAIN_FEATURE_METADATA_SUCCESS: {
      if (!action.payload.data) return state;
      return {
        ...state,
        featureMetadata: {
          ...state.featureMetadata,
          [action.payload.geoid]: action.payload.data.map(g => ({
            ...g,
            values: g.values.map(v => ({ ...v, year: new Date(v.date).getFullYear() })),
          })),
        },
      };
    }

    /**
     * Pools */
    case types.MAIN_ADD_TO_POOL: {
      if (Array.isArray(action.payload)) {
        const newPool = action.payload.reduce((pools, thisPool) => {
          return { ...pools, [thisPool.geoid]: thisPool.name };
        }, state.activePool);

        return {
          ...state,
          activePool: newPool,
        };
      }
      return {
        ...state,
        activePool: { ...state.activePool, [action.payload.geoid]: action.payload.name },
      };
    }

    case types.MAIN_REMOVE_FROM_POOL: {
      const pool = { ...state.activePool };
      if (pool[action.payload.geoid] !== undefined) delete pool[action.payload.geoid];
      return {
        ...state,
        activePool: pool,
      };
    }

    case types.MAIN_CLEAR_ACTIVE_POOL: {
      return {
        ...state,
        activePool: {},
      };
    }

    case types.MAIN_CREATE_POOL: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_POOL_SUCCESS: {
      const { entities, geojson } = action.payload;
      const names = Object.values(entities);
      const name =
        names.length > 2
          ? `${names[0]} + ${names.length - 1} more`
          : names.slice(0, 2).join(' and ');
      return {
        ...state,
        loading: false,
        pools: [
          ...state.pools,
          {
            entities,
            name,
            geoid: geojson.features[0].properties.geoid,
            geojson: {
              ...geojson,
              features: geojson.features.map(feature => ({
                ...feature,
                id: Date.now(),
                properties: {
                  ...feature.properties,
                  pool: true,
                  name,
                },
              })),
            },
          },
        ],
        activePool: {},
      };
    }

    case types.MAIN_DELETE_POOL: {
      // delete any pool containing passed geoid, on assumption it would only be in one
      // if no geoid, clears all pools
      const pools = action.payload
        ? state.pools.filter(p => p.entities[action.payload] === undefined)
        : [];
      const inactivePools = { ...state.inactivePools };
      inactivePools[state.currentGeography.id] = pools;
      return {
        ...state,
        pools,
        inactivePools,
      };
    }

    /**
     * Rank */

    case types.MAIN_GET_RANK_DATA: {
      if (action.payload && action.payload.export) return state; // do not change any state for CSV export
      // remove ranks from "active changes" if clearning, since this can happen when the panel isn't open
      const newActive = action.payload
        ? state.activeChanges
        : state.activeChanges.filter(d => d.view !== 'rank-by-geography');
      return {
        ...state,
        rankData: action.payload ? { data: action.payload, ranks: [] } : null,
        loading: true,
        activeChanges: newActive,
      };
    }

    case types.MAIN_RANK_DATA_SUCCESS: {
      const rankData = action.payload;
      const { allIndicatorData, currentIndicator, allPoolData, currentGeography } = state;

      // rank data can force a change in geography, but this is handled in rankEpic

      // if not rescaled, current data is the basic geography/indicator set, but it may not exist (yet) if geography change was involved
      const indicatorData =
        allIndicatorData[currentGeography.id] &&
        allIndicatorData[currentGeography.id][currentIndicator.id] &&
        allIndicatorData[currentGeography.id][currentIndicator.id][currentIndicator.year]
          ? allIndicatorData[currentGeography.id][currentIndicator.id][currentIndicator.year]
          : [];
      const poolData = allPoolData[currentGeography.id]
        ? allPoolData[currentGeography.id][currentIndicator.id].all
        : [];

      if (rankData && rankData.data) {
        return {
          ...state,
          rankData,
          currentIndicatorData: rankData.data.rescale ? rankData.ranks : indicatorData,
          currentPoolData: rankData.data.rescale ? [] : poolData,
          loading: false,
        };
      }
      if (rankData && !rankData.data) {
        // empty object returned after CSV export; do not change anything
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        rankData,
        currentIndicatorData: indicatorData,
        currentPoolData: poolData,
        loading: false,
      };
    }

    /**
     * Login */

    case types.MAIN_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        username: action.payload.username,
      };
    }

    case types.MAIN_LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.payload,
      };
    }

    case types.MAIN_LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        username: null,
        savedViews: [],
      };
    }

    case types.MAIN_API_KEY_SUCCESS: {
      return {
        ...state,
        apiKey: action.payload,
      };
    }

    /**
     * Saved views */

    case types.MAIN_RECEIVED_VIEWS: {
      return {
        ...state,
        savedViews: action.payload,
      };
    }

    case types.MAIN_LOAD_SAVED_VIEW: {
      const { defaultIndicator } = state;
      return {
        ...state,
        currentIndicator:
          getIndicator(action.payload.indicator, state.indicatorList, state.layers) ||
          defaultIndicator,
        currentGeography: state.geographiesList.find(g => g.id === action.payload.geography),
      };
    }

    /**
     * Import */

    case types.MAIN_LAYERS_SUCCESS: {
      return {
        ...state,
        layers: action.payload.map(l => ({ ...l, colors: valueColors })),
      };
    }

    case types.MAIN_UPLOAD_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }

    case types.MAIN_UPLOAD_SUCCESS: {
      return {
        ...state,
        currentLayerImport: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_UPLOAD_ERROR: {
      return {
        ...state,
        importError: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_SAVE_LAYER: {
      return {
        ...state,
        importLoading: true,
      };
    }

    case types.MAIN_SAVE_LAYER_SUCCESS: {
      return {
        ...state,
        importError: null,
        currentLayerImport: null,
        importLoading: false,
      };
    }

    case types.MAIN_SAVE_LAYER_ERROR: {
      return {
        ...state,
        importError: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_LOAD_LAYER_DATA: {
      return {
        ...state,
        currentIndicator: action.payload.type === 'csv' ? action.payload : state.currentIndicator,
        currentUserLayer: action.payload.type === 'csv' ? action.payload : state.currentUserLayer,
        loading: true,
      };
    }

    case types.MAIN_ADD_OVERLAY: {
      const overlay = {
        ...action.payload,
        color: getOverlayColor(state.currentOverlays),
      };
      return {
        ...state,
        currentOverlays: [...state.currentOverlays, overlay],
        loading: false,
      };
    }

    case types.MAIN_REMOVE_OVERLAY: {
      return {
        ...state,
        currentOverlays: action.payload
          ? state.currentOverlays.filter(d => d.id !== action.payload.id)
          : [],
        loading: false,
      };
    }

    case types.MAIN_SET_CURRENT_USER_LAYER: {
      return {
        ...state,
        currentUserLayer: action.payload,
      };
    }

    /**
     * Export */
    case types.MAIN_EXPORT_MAP: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.EXPORT_MAP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    /**
     * Policy panel */
    case types.MAIN_GET_POLICIES: {
      return {
        ...state,
        policyOpportunities: [],
      };
    }

    case types.MAIN_POLICIES_SUCCESS: {
      const policyOpportunities = Object.entries(action.payload.policies).map(([domain, items]) => {
        return {
          domain,
          guides: items,
        };
      });

      if (!action.payload.geoid) {
        return {
          ...state,
          policyOpportunities,
          defaultPolicies: action.payload.policies,
        };
      }
      return {
        ...state,
        policyOpportunities,
      };
    }

    case types.MAIN_SET_SELECTED_POLICY: {
      return {
        ...state,
        selectedPolicy: action.payload,
      };
    }

    case types.MAIN_SET_CUSTOM_SCORE: {
      const hpiIndicators = state.indicatorList
        .filter(d => d.weight > 0)
        .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
        .map(i => i.id);
      if (!action.payload) {
        return {
          ...state,
          customScore: {
            domains: [],
            indicators: hpiIndicators,
          },
          currentIndicator: state.defaultIndicator,
          loading: true,
        };
      }
      return {
        ...state,
        customScore: action.payload,
        loading: true,
      };
    }

    case types.MAIN_CUSTOM_SCORE_SUCCESS: {
      if (action.payload) {
        const newData = addIndicatorData(
          state.allIndicatorData,
          action.payload.data
            ? [
                {
                  geography: state.currentGeography.id,
                  indicator: action.payload.id,
                  data: action.payload.data,
                },
              ]
            : null
        );
        return {
          ...state,
          allIndicatorData: newData,
          currentIndicator: {
            id: action.payload.id,
            title: 'Custom Score',
            mapPercentile: true,
            formatValue: d => d,
            getYearDisplay: d => d,
          },
          currentIndicatorData:
            action.payload.data ||
            state.allIndicatorData[state.currentGeography.id][action.payload.id].all,
          loading: false,
        };
      }
      return {
        ...state,
        currentIndicator: state.defaultIndicator,
        currentIndicatorData:
          state.allIndicatorData[state.currentGeography.id][state.defaultIndicator.id][
            state.defaultIndicator.latestYear
          ],
        loading: false,
      };
    }

    case types.MAIN_SET_MULTIPLE_VULNERABILITIES: {
      if (!action.payload) {
        return {
          ...state,
          multipleVulnerabilities: [],
        };
      }
      return {
        ...state,
        multipleVulnerabilities: action.payload,
      };
    }

    case types.MAIN_MULTIPLE_VULNERABILITIES_SUCCESS: {
      const {
        multipleVulnerabilities,
        // choroplethClassification,
        allIndicatorData,
        currentGeography,
      } = state;
      // keep a custom color scheme, but change to vulnerabilityColors if default
      // const colors = choroplethClassification.every(
      //   (c, i) => c.color === sequentialColors[i] || c.color === divergingColors[i]
      // )
      //   ? vulnerabilityColors
      //   : choroplethClassification.map(c => c.color);
      let classification = vulnerabilityColors.map(c => ({
        min: Infinity,
        max: Infinity,
        color: c,
      }));
      if (multipleVulnerabilities.length === 1) {
        classification[0].min = 0;
        classification[0].max = 0.99;
        classification[3].min = 0.99;
      } else if (multipleVulnerabilities.length === 2) {
        classification[0].min = 0;
        classification[0].max = 0.99;
        classification[1].min = 0.99;
        classification[1].max = 1.99;
        classification[3].min = 1.99;
      } else {
        classification = classification.map((c, i) => ({
          ...c,
          min: (i * multipleVulnerabilities.length) / 4 - 0.01,
          max: ((i + 1) * multipleVulnerabilities.length) / 4 - 0.01,
        }));
        classification[classification.length - 1].max = Math.ceil(
          classification[classification.length - 1].max
        );
      }
      const geogs = multipleVulnerabilities
        .map(id => {
          const indicator = state.allIndicators.find(i => i.id === id);
          return indicator.Geographies;
        })
        .filter(g => g && g.length);

      const combinedGeogs = intersection(...geogs);

      const data = action.payload.data.map(d => ({
        ...d,
        indicators: uniq(d.indicators),
        value: uniq(d.indicators).length,
      }));

      const allData = {
        ...allIndicatorData,
        [currentGeography.id]: {
          ...allIndicatorData[currentGeography.id],
          vulnerability: data,
        },
      };

      return {
        ...state,
        allIndicatorData: allData,
        currentIndicator: {
          id: 'vulnerability',
          title: 'Multiple Vulnerabilities',
          Geographies: Array.from(combinedGeogs),
        },
        currentIndicatorData: data,
        attribute: 'value',
        classificationMethod: 'vulnerability',
        choroplethClassification: classification,
        choroplethFilter: [classification[0].min, classification[classification.length - 1].max],
        currentPoolData:
          action.payload.pools && action.payload.pools.length > 0
            ? state.currentPoolData.map(p => {
                const poolCopy = { ...p };
                poolCopy.IndicatorId = 'vulnerability';
                delete poolCopy.numerator;
                delete poolCopy.percentile;
                delete poolCopy.value;
                const newPoolData = action.payload.pools.find(d => d.geoid === p.geoid);
                if (newPoolData) {
                  const { value, indicators } = newPoolData;
                  return { ...poolCopy, value, indicators };
                }
                return poolCopy;
              })
            : [],
      };
    }

    case types.MAIN_SET_ENV:
      return {
        ...state,
        env: action.payload,
      };

    /**
     * Test */

    case types.MAIN_TEST:
      return {
        ...state,
        loading: true,
      };

    case types.MAIN_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case types.MAIN_TEST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, main);
