//  index.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./covid-panel.component'),
  loading: () => null,
});
