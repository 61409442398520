import * as types from './main.actionType';
import * as actions from './main.action';
import { setViewport } from '@components/map-components/map-container/map-container.action';
import { extent } from 'd3';
import { ckmeans, mean } from 'simple-statistics';
import { omit } from 'lodash';
import { saveAs } from 'file-saver';

const getUrlParam = name => {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
};

const getCurrentStateData = (main, year, which = 1) => {
  const {
    currentIndicator,
    currentIndicator2,
    allIndicatorData,
    geographiesList,
    currentGeography,
  } = main;
  const state = geographiesList.find(g => g.name === 'State');
  if (!state) return null;
  const indicator = which === 1 ? currentIndicator : currentIndicator2;
  const indicatorId = indicator.id;
  let stateValue = null;
  // for stratified indicators, use the state mean of the parent or "all" indicator as the basis for class breaks
  if (allIndicatorData[state.id]) {
    if (allIndicatorData[state.id][indicatorId]) {
      if (indicator.parentIndicator) {
        // for stratified let's just look at newest year on parent; will force the same class breaks in compare mode
        // otherwise selected year may not exist on parent and this function would return null, resulting in different breaks for different strata
        const latestYear = Math.max(
          ...Object.keys(allIndicatorData[state.id][indicatorId]).map(y => +y)
        );
        stateValue = allIndicatorData[state.id][indicatorId][latestYear];
        console.log(currentGeography.stats.find(s => s.id === indicatorId));
        if (currentGeography.stats && currentGeography.stats.find(s => s.id === indicatorId)) {
          const indicatorStats = currentGeography.stats.find(s => s.id === indicatorId);
          if (indicatorStats) {
            // also send min and max from parent indicator (for calculating class breaks)
            const { min, max, mean: sliceMean } = indicatorStats;
            stateValue[0] = {
              ...stateValue[0],
              value: sliceMean,
              min,
              max,
            };
          }
        }
      }
      stateValue = allIndicatorData[state.id][indicatorId][year];
    }
  }
  if (stateValue === null && currentGeography.stats) {
    // use mean from geography stats, if available
    const id = indicator.parentIndicator ? indicator.parentIndicator.id : indicatorId;
    const stats = currentGeography.stats.find(s => s.id === id);
    if (stats) stateValue = [{ value: stats.mean }];
  }
  return stateValue;
};

const getClassificationData = (data, stateData = [{}], method, currentAttribute) => {
  if (!data || !data.length) {
    return {
      breakVals: [
        [0, 0.25],
        [0.25, 0.5],
        [0.5, 0.75],
        [0.75, 1],
      ],
      attribute: currentAttribute,
    };
  }
  let attribute;
  const m = method.toLowerCase();
  const vals = data.map(d => d.value);
  // stateData should be an array of one data point
  let sData;
  if (!stateData || !stateData.length) sData = [{}];
  // an empty array can sometimes from from the API
  else [sData] = stateData;
  const [min, max] =
    sData.min !== undefined && sData.max !== undefined
      ? [sData.min, sData.max]
      : extent(data, d => d.value);
  const avg = sData.value || mean(vals); // fall back on mean of current geog, if no state value ... though getCurrentStateData function now already includes that fallback
  let breaks;
  if (m === 'quartiles') {
    breaks = [0, 0.25, 0.5, 0.75, 1];
    attribute = 'percentile';
  } else if (vals.length === 1 && vals[0] !== null && vals[0] !== undefined) {
    // catch single-entity data and create breaks that will be weird but won't ruin the map
    return {
      breakVals: Array(5).fill(vals[0]),
      attribute: 'value',
    };
  } else if (vals.length === 1) {
    return {
      breakVals: [0, 0.25, 0.5, 0.75, 1],
      attribute: 'value',
    };
  }
  // non-percentile methods always use state mean as the central break
  else if (m === 'natural breaks') {
    const aboveBelowMean = vals.reduce(
      (memo, val) => {
        if (val === null) return memo;
        if (val >= avg) {
          return [memo[0], [...memo[1], val]];
        }
        return [[...memo[0], val], memo[1]];
      },
      [[], []]
    );
    // get two-class natural breaks above and below mean
    const binsBelow = ckmeans(aboveBelowMean[0], 2).map(b => [b[0], b[b.length - 1]]);
    const binsAbove = ckmeans(aboveBelowMean[1], 2).map(b => [b[0], b[b.length - 1]]);
    breaks = [min, binsBelow[1][0], avg, binsAbove[1][0], max];
    attribute = 'value';
  } else {
    // "equal" interval
    breaks = [min, min + (avg - min) / 2, avg, avg + (max - avg) / 2, max];
    attribute = 'value';
  }
  const breakVals = breaks.slice(0, 4).map((b, i) => [b, breaks[i + 1]]);
  return { breakVals, attribute };
};

// sets up a series of actions to occur in sequence

export const indicatorEpic = (action$, store, { getJSON, of }) => {
  return action$.ofType(types.MAIN_LOAD_INDICATOR_LIST).mergeMap(() =>
    // to do: check if already loaded
    getJSON(`/api/v1/indicators`)
      .map(response => actions.loadIndicatorListSuccess(response))
      .catch(error => of(actions.testError(error)))
  );
};

export const indicatorSuccessEpic = (action$, store, { of }) => {
  return action$
    .ofType(types.MAIN_LOAD_INDICATOR_LIST_SUCCESS)
    .mergeMap(() => of(actions.loadGeographiesList()));
};

export const geographiesEpic = (action$, store, { getJSON, of }) => {
  return action$.ofType(types.MAIN_LOAD_GEOGRAPHIES_LIST).mergeMap(() =>
    // to do: check if already loaded
    getJSON(`/api/v1/geographies`)
      .map(response => actions.loadGeographiesListSuccess(response))
      .catch(error => of(actions.testError(error)))
  );
};

export const geographiesListSuccessEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS).mergeMap(() => {
    const { main } = store.value;
    const { currentGeography } = main;
    const savedView = getUrlParam('view');
    if (savedView) {
      return of(actions.getViewById(savedView));
    }
    return of(actions.setGeography(currentGeography.id));
  });
};

export const setGeographyEpic = (action$, store, { of, ajax }) => {
  return action$.ofType(types.MAIN_SET_GEOGRAPHY).mergeMap(() => {
    const { main } = store.value;
    const {
      indicatorList,
      currentIndicator,
      currentIndicator2,
      currentGeography,
      customScore,
      demographicsFilter,
      pools,
    } = main;

    const hpiIndicators = indicatorList
      .filter(d => d.weight > 0)
      .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
      .map(i => i.id);
    const isCustomScore =
      customScore.domains.length ||
      hpiIndicators.some(i => customScore.indicators.indexOf(i) === -1);
    const isVulnerability = main.multipleVulnerabilities && main.multipleVulnerabilities.length;
    if (!currentGeography.stats) {
      return ajax({
        url: `/api/v1/stats/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ geography: currentGeography.id, year: currentIndicator.year }),
      })
        .mergeMap(response => {
          let dataAction;
          if (isCustomScore) dataAction = actions.setCustomScore(customScore);
          else if (isVulnerability)
            dataAction = actions.setMultipleVulnerabilities(main.multipleVulnerabilities);
          else
            dataAction = actions.loadIndicatorData({
              indicator: currentIndicator.id,
              geography: currentGeography.id,
              which: 1,
              year: currentIndicator.year,
            });
          const actionsToDo = [
            actions.geographiesStatsSuccess({
              geography: currentGeography.id,
              stats: response.response.response,
            }),
            dataAction,
          ];
          if (currentIndicator2)
            actionsToDo.push(
              actions.loadIndicatorData({
                indicator: currentIndicator2.id,
                geography: currentGeography.id,
                which: 2,
                year: currentIndicator.year2,
              })
            );

          if (demographicsFilter.races.length) {
            actionsToDo.push(actions.setDemographicsFilter(demographicsFilter));
          }
          return of(...actionsToDo);
        })
        .catch(error => of(actions.testError(error)));
    }
    let dataAction;
    if (isCustomScore) {
      dataAction = actions.setCustomScore(customScore);
    } else if (isVulnerability) {
      dataAction = actions.setMultipleVulnerabilities(main.multipleVulnerabilities);
    } else {
      dataAction = actions.loadIndicatorData({
        indicator: currentIndicator.id,
        geography: currentGeography.id,
        which: 1,
        year: currentIndicator.year,
      });
    }
    const actionsToDo = [dataAction];
    console.log(pools);
    if (pools.length > 0) {
      const allPoolGeoids = pools.map(p => p.geoid);
      actionsToDo.push(
        actions.loadIndicatorData({
          isPools: true,
          geoids: allPoolGeoids,
          indicator: currentIndicator.id,
          geography: currentGeography.id,
          which: 1,
          year: currentIndicator.year,
        })
      );
    }
    if (currentIndicator2)
      actionsToDo.push(
        actions.loadIndicatorData({
          indicator: currentIndicator2.id,
          geography: currentGeography.id,
          which: 2,
          year: currentIndicator.year2,
        })
      );
    if (demographicsFilter.races.length) {
      actionsToDo.push(actions.setDemographicsFilter(demographicsFilter));
    }
    return of(...actionsToDo);
  });
};

export const indicatorFilterEpic = (action$, store, { of, ajax, forkJoin }) => {
  return action$.ofType(types.MAIN_SET_INDICATOR_FILTER).mergeMap(action => {
    const { main } = store.value;
    if (!action.payload || !action.payload.values) {
      return of(actions.setIndicatorFilterSuccess(action.payload));
    }

    // below duplicates chunks of indicatorDataEpic, because filtering on an indicator requires its data to exist

    const { indicator } = action.payload;
    const { currentGeography, geographiesList, allIndicatorData, allIndicators } = main;
    const state = geographiesList.find(g => g.name === 'State');
    const body = {
      indicator,
      geography: currentGeography.id,
      attributes: ['value', 'percentile'],
    };

    const indicatorMeta = allIndicators.find(i => i.id === indicator);

    // get the data request functions
    const fetchMainData = () => {
      return {
        body,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }),
      };
    };
    const fetchStateData = () => {
      const stateBody = {
        indicator: body.indicator,
        geography: state.id,
        attributes: ['value', 'percentile'],
      };
      return {
        body: stateBody,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(stateBody),
        }),
      };
    };

    // check if data already exists for the main geography (e.g. tracts)
    let mainDataLoaded = false;
    const existingData = allIndicatorData;
    if (
      existingData &&
      existingData[body.geography] &&
      existingData[body.geography][body.indicator] &&
      existingData[body.geography][body.indicator][indicatorMeta.latestYear]
    ) {
      // data already exists
      mainDataLoaded = true;
    }

    // check if state-level data exists
    const stateDataLoaded =
      state &&
      existingData[state.id] &&
      existingData[state.id][body.indicator] &&
      existingData[state.id][body.indicator][indicatorMeta.latestYear];

    // request main (e.g. tracts or pools) data and/or state data as needed
    const requests = [];
    if (!mainDataLoaded) requests.push(fetchMainData());
    // also skip state data if this is a pool request
    if (!stateDataLoaded) requests.push(fetchStateData());

    const actionsToDo = [];
    if (!requests.length) {
      // there's truly no new data to load
      actionsToDo.push(actions.setIndicatorFilterSuccess(action.payload));
      return of(...actionsToDo);
    }

    return forkJoin(...requests.map(r => r.req))
      .mergeMap(res => {
        // data for each geography requested (possibly two, including state level)
        const successData = res.reduce((combined, r, i) => {
          const reqBody = requests[i].body;
          return {
            ...combined,
            [reqBody.geography]: {
              ...reqBody,
              isPools: action.payload.isPools,
              data: r.response.response,
            },
          };
        }, {});
        actionsToDo.push(
          actions.loadIndicatorDataSuccess({
            // send back the indicator/geog actually requested (e.g. tracts) for clarity
            ...body,
            geographies: successData, // contains the actual data (state and/or e.g. tracts)
            dataOnly: true,
            year: indicatorMeta.latestYear,
          }),
          actions.setIndicatorFilterSuccess(action.payload)
        );
        return of(...actionsToDo);
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const demographicsFilterEpic = (action$, store, { of, ajax }) => {
  return action$.ofType(types.MAIN_SET_DEMOGRAPHICS_FILTER).mergeMap(action => {
    const { main } = store.value;
    if (!action.payload || !action.payload.races) {
      return of(actions.setDemographicsFilterSuccess());
    }

    // below duplicates chunks of indicatorDataEpic, because filtering on an indicator requires its data to exist

    const { races, threshold, attribute } = action.payload;
    const { currentGeography } = main;
    const body = {
      races,
      threshold,
      attribute,
      geography: currentGeography.id,
    };

    return ajax({
      url: `/api/v1/race/`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .mergeMap(res => {
        return of(
          actions.setDemographicsFilterSuccess({ ...action.payload, geoids: res.response.response })
        );
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const setIndicatorEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_SET_INDICATOR).mergeMap(action => {
    const { main } = store.value;
    // `which` is to specify indicator 1 or 2 in split screen (default 1 when undefined in other modes)
    const { indicator, which, year } = action.payload;
    // clear split screen
    if (which === 2 && indicator === null) {
      return of(actions.loadIndicatorDataSuccess());
    }
    const actionsToDo = [
      actions.loadIndicatorData({ indicator: indicator || main.defaultIndicator.id, which, year }),
    ];
    console.log(main.pools);
    console.log(action.payload);
    if (main.pools && main.pools.length) {
      // load main data plus pool data
      actionsToDo.push(
        actions.loadPoolData({ indicator: indicator || main.defaultIndicator.id, which, year })
      );
    }
    return of(...actionsToDo);
  });
};

export const poolDataEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_LOAD_POOL_DATA).mergeMap(action => {
    const { geoids, indicator, geography, which, year } = action.payload;
    const { main } = store.value;
    const { pools } = main;
    const allPoolGeoids = pools.map(p => p.geoid);
    console.log(action.payload);
    return of(
      actions.loadIndicatorData({
        isPools: true,
        geoids: geoids || allPoolGeoids,
        indicator,
        geography,
        which,
        year,
      })
    );
  });
};

// gets data for a given indicator, geography, and optional set of geoids (pools)
// except in the case of pools, this will request state-level data in addition
export const indicatorDataEpic = (action$, store, { ajax, of, forkJoin }) => {
  return action$.ofType(types.MAIN_LOAD_INDICATOR_DATA).mergeMap(action => {
    const { main } = store.value;
    const {
      allIndicatorData,
      allPoolData,
      classificationMethod,
      classificationMethod2,
      geographiesList,
      rankData,
      currentIndicator,
      currentIndicator2,
      currentGeography,
    } = main;

    // note currentIndicator and currentGeography have already been updated in the reducer before now

    if (currentIndicator.layerId) {
      // this is a custom layer. don't do the normal loading
      // we'd arrive here if a custom layer was loaded and we switched geographies or something
      return of(
        actions.loadIndicatorDataSuccess({
          indicator: currentIndicator.id,
          geography: currentGeography.id,
          isPools: action.payload.isPools,
          geographies: null,
        }),
        actions.setClassificationMethod({ method: classificationMethod }) // updates choropleth classification if needed)
      );
    }

    // `which` is to specify indicator 1 or 2 in split screen (default 1 when undefined in other modes)
    const { indicator, geography, geoids, isPools, which, year } = action.payload;
    const state = geographiesList.find(g => g.name === 'State'); // state geography

    // body to send to data request
    const body = {
      indicator: indicator || main.currentIndicator.id,
      geography: geography || main.currentGeography.id,
      geoids, // <- for pool data
      attributes: ['value', 'percentile'],
    };

    if (year && year !== 'all') {
      body.year = year;
    } else if (currentIndicator.latestYear) {
      body.year = currentIndicator.latestYear;
    }

    // get the data request functions (these are called only if necessary)
    const fetchMainData = () => {
      return {
        body,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }),
      };
    };
    const fetchStateData = () => {
      const stateBody = {
        indicator: body.indicator,
        geography: state.id,
        attributes: ['value', 'percentile'],
      };
      if (body.year) {
        stateBody.year = body.year;
      }
      return {
        body: stateBody,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(stateBody),
        }),
      };
    };

    // check if data already exists for the main geography (e.g. tracts)
    let mainDataLoaded = false;
    const existingData = isPools ? allPoolData : allIndicatorData;
    if (
      existingData &&
      existingData[body.geography] &&
      existingData[body.geography][body.indicator] &&
      existingData[body.geography][body.indicator][year || 'all']
    ) {
      // data already exists
      mainDataLoaded = true;
      if (geoids) {
        // ...but we want specific geoids (probably pools) and their data may not exist yet
        const existingGeoids = existingData[body.geography][body.indicator][year || 'all'].map(
          d => d.geoid
        );
        const geoidsToRequest = geoids.filter(id => !existingGeoids.includes(id));
        if (geoidsToRequest.length) {
          mainDataLoaded = false;
          body.geoids = geoidsToRequest;
        }
      }
    }

    // check if state-level data exists
    const stateDataLoaded =
      state &&
      existingData[state.id] &&
      existingData[state.id][body.indicator] &&
      existingData[state.id][body.indicator][year || 'all'];

    // request main (e.g. tracts or pools) data and/or state data as needed
    const requests = [];
    if (!mainDataLoaded) requests.push(fetchMainData());
    // also skip state data if this is a pool request
    if (!stateDataLoaded && !isPools) requests.push(fetchStateData());

    // ranks, if any, may need to be updated
    let rankAction = null;
    if (rankData && rankData.data && !isPools) {
      const { data } = rankData;
      if (data.geography !== body.geography || which === 2) {
        // clear rank if swichting geography or entering split screen
        rankAction = actions.getRankData(null);
      } else if (data.indicator !== body.indicator || data.year !== year) {
        // if switching indicator, load new ranks
        rankAction = actions.getRankData({
          ...data,
          indicator: body.indicator,
          year,
        });
      }
    }

    const actionsToDo = [];
    let classificationMethodToUse = which !== 2 ? classificationMethod : classificationMethod2;
    // stratified indicators use equal interval
    if (which !== 2 && currentIndicator.parentIndicator)
      classificationMethodToUse = 'Equal Interval';
    if (which === 2 && currentIndicator2.parentIndicator)
      classificationMethodToUse = 'Equal Interval';

    if (!requests.length) {
      // there's truly no new data to load
      // if (!geography) {

      // }
      actionsToDo.push(
        actions.loadIndicatorDataSuccess({
          ...body,
          isPools: action.payload.isPools,
          geographies: null,
          which,
          year: year || (which === 2 ? main.currentIndicator2 : main.currentIndicator).latestYear,
        }),
        actions.setClassificationMethod({
          method: classificationMethodToUse,
          which,
        }) // updates choropleth classification if needed)
      );
      if (rankAction) actionsToDo.push(rankAction);
      // switch to default geography
      let availableGeogs = main.geographiesList.filter(
        g => g.layer !== 'state' && currentIndicator.Geographies.includes(g.layer)
      );
      if (currentIndicator2)
        availableGeogs = availableGeogs.filter(g =>
          currentIndicator2.Geographies.includes(g.layer)
        );

      const geogToLoad =
        availableGeogs.find(g => g.id === geography) ||
        availableGeogs.find(g => g.default) ||
        availableGeogs[0];
      if (geogToLoad !== currentGeography) {
        actionsToDo.push(actions.setGeography(geogToLoad.id));
      }
      // find smallest geography that the layer includes (if none, default to counties)

      return of(...actionsToDo);
    }

    // if there is new data to load...

    return forkJoin(...requests.map(r => r.req))
      .mergeMap(res => {
        // data for each geography requested (possibly two, including state level)
        const successData = res.reduce((combined, r, i) => {
          const reqBody = requests[i].body;
          return {
            ...combined,
            [reqBody.geography]: {
              ...reqBody,
              isPools: action.payload.isPools,
              data: r.response.response.filter(d => d !== null),
            },
          };
        }, {});
        actionsToDo.push(
          actions.loadIndicatorDataSuccess({
            // send back the indicator/geog actually requested (e.g. tracts) for clarity
            ...body,
            geographies: successData, // contains the actual data (state and/or e.g. tracts)
            isPools: action.payload.isPools,
            which,
            year: year || (which === 2 ? main.currentIndicator2 : main.currentIndicator).latestYear,
          }),
          actions.setClassificationMethod({
            method: classificationMethodToUse,
            which,
          }) // updates choropleth classification if needed)
        );
        if (rankAction) actionsToDo.push(rankAction);
        // switch to default geography
        let availableGeogs = main.geographiesList.filter(
          g => g.layer !== 'state' && currentIndicator.Geographies.includes(g.layer)
        );
        if (currentIndicator2)
          availableGeogs = availableGeogs.filter(g =>
            currentIndicator2.Geographies.includes(g.layer)
          );

        const geogToLoad =
          availableGeogs.find(g => g.id === geography) ||
          availableGeogs.find(g => g.default) ||
          availableGeogs[0];

        if (geogToLoad !== currentGeography) {
          // do not switch to smallest geography when for split screen map unless necessary
          if (which === 1 || !availableGeogs.includes(currentGeography))
            actionsToDo.push(actions.setGeography(geogToLoad.id));
        }
        return of(...actionsToDo);
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const histogramEpic = (action$, store, { ajax, of }) => {
  return action$.ofType(types.MAIN_LOAD_HISTOGRAM_DATA).mergeMap(action => {
    const { main } = store.value;
    const bins = main.currentGeography.features
      ? Math.min(main.currentGeography.features.length, 40)
      : 40;
    const body = {
      ...action.payload,
      bins,
    };
    // fetch data
    return ajax({
      url: `/api/v1/histogram/`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .map(response => {
        return actions.histogramDataSuccess({
          ...body,
          data: response.response.response,
        });
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const classificationEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_SET_CLASSIFICATION_METHOD).mergeMap(action => {
    const { method, which } = action.payload;

    const { main } = store.value;
    const {
      currentIndicatorData,
      choroplethClassification,
      currentIndicatorData2,
      choroplethClassification2,
      currentIndicator,
      currentIndicator2,
    } = main;

    // classification data for map 1
    const stateData = getCurrentStateData(main, currentIndicator.year2);
    const { breakVals, attribute } = getClassificationData(
      currentIndicatorData,
      stateData,
      method,
      main.attribute
    );
    const newClassification = choroplethClassification.map((c, i) => {
      return {
        ...c,
        min: breakVals[i][0],
        max: breakVals[i][1],
      };
    });

    const actionsToDo = [];

    if (which !== 2) {
      actionsToDo.push(
        actions.setClassification({ classification: newClassification, which: 1 }),
        actions.setAttribute({ attribute, which }),
        actions.setChoroplethFilter(null)
      );
    }

    // classification data for map 2
    if (
      currentIndicatorData2 &&
      currentIndicatorData2.length &&
      (which === 2 || which === undefined)
    ) {
      const stateData2 = getCurrentStateData(main, currentIndicator2.year2, 2);
      const breakVals2 = getClassificationData(
        currentIndicatorData2,
        stateData2,
        method,
        main.attribute2
      ).breakVals;
      const newClassification2 = choroplethClassification2.map((c, i) => {
        return {
          ...c,
          min: breakVals2[i][0],
          max: breakVals2[i][1],
        };
      });
      actionsToDo.push(
        actions.setClassification({ classification: newClassification2, which: 2 }),
        actions.setAttribute({ attribute, which })
      );
    }

    return of(...actionsToDo);
  });
};

/**
 * Feature data
 */

export const featureConditionsEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOAD_FEATURE_CONDITION_DATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { geoid } = action.payload;

      // fetch feature data
      const body = {
        geography: main.currentGeography.id,
        geoid,
        attributes: ['percentile', 'value', 'numerator', 'classification', 'label'],
        indicator: main.allIndicators.map(i => i.id),
        year: main.currentIndicator.year,
      };
      return ajax({
        url: `/api/v1/conditions/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          const inds = response.response.response.map(cat => ({
            ...cat,
            indicators: cat.indicators.map(ind => ({
              ...main.allIndicators.find(i => i.id === ind.id),
              ...ind,
            })),
          }));
          return of(actions.featureConditionDataSuccess({ geoid, data: inds }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const featureMetadataEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOAD_FEATURE_METADATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { geoid } = action.payload;
      const indicator = main.currentIndicator.id;

      // fetch feature data
      const body = {
        geography: main.currentGeography.id,
        indicator: main.allIndicators.map(i => i.id),
        geoid,
        attributes: ['value', 'percentile', 'label'],
      };
      return ajax({
        url: `/api/v1/feature/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          return of(
            actions.featureMetadataSuccess({ geoid, indicator, data: response.response.response })
          );
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * Pooling
 */

export const createPoolEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_CREATE_POOL)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, currentIndicator } = main;

      // fetch feature data
      const body = {
        geography: currentGeography.id,
        indicator: currentIndicator.id,
      };
      if (action.payload.poolId) {
        body.geoid = action.payload.poolId;
      } else if (action.payload.geom) {
        body.geom = action.payload.geom;
      } else {
        body.geoids = [Object.keys(action.payload)];
      }

      return ajax({
        url: `/api/v1/pool/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          let entities;
          const geojson = response.response.response;
          const feature = geojson.features[0];
          if (action.payload.geom) {
            // pool from polygon, need to create entities object

            entities = feature.properties.geoids.reduce((ents, geoid, i) => {
              return {
                ...ents,
                [geoid]: feature.properties.names[i],
              };
            }, {});
          } else {
            entities = action.payload;
          }

          if (feature.properties.percentile !== undefined) {
            // update pool data if we received any
            return of(
              actions.poolSuccess({ entities, geojson }),
              actions.loadIndicatorDataSuccess({
                geography: currentGeography.id,
                indicator: currentIndicator.id,
                isPools: true,
                geographies: {
                  [currentGeography.id]: {
                    geography: currentGeography.id,
                    indicator: currentIndicator.id,
                    data: [feature.properties],
                  },
                },
              })
            );
          }
          return of(actions.poolSuccess({ entities, geojson }));
        })
        .catch(error => {
          if (action.payload.callback) action.payload.callback(error);
          return of(actions.testError(error));
        });
    })
    .catch(() => []);
};

/**
 * rank
 */

export const rankEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_RANK_DATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, classificationMethod } = main;
      if (!action.payload) {
        // classification may need to be reset if the rank data had been rescaled
        return of(
          actions.rankDataSuccess(null),
          actions.setClassificationMethod({ method: classificationMethod })
        );
      }

      const body = omit(action.payload, 'export');

      if (action.payload.within) {
        body.within = omit(action.payload.within, 'name');
      }

      return ajax({
        url: `/api/v1/rank/${action.payload.export ? '?format=csv' : ''}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: action.payload.export ? 'blob' : 'json',
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          if (action.payload.export) {
            saveAs(response.response, 'ranks.csv');
            return of(actions.rankDataSuccess({}));
          }
          const actionsToDo = [
            actions.rankDataSuccess({
              data: omit(action.payload, 'export'),
              ranks: response.response.response.reverse(),
            }),
          ];
          if (action.payload.geography !== currentGeography.id) {
            // rank options can require a change in geography
            actionsToDo.unshift(actions.setGeography(action.payload.geography));
          } else {
            // classification may need to be reset if the rank data is or previously was rescaled
            // if geog changed (above), this will still happen via that action
            actionsToDo.push(actions.setClassificationMethod({ method: classificationMethod }));
          }
          return of(...actionsToDo);
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * login
 */

export const loginEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOGIN)
    .mergeMap(action => {
      return ajax({
        url: `/login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.setLoginError(response.response.responseMessage));
          }
          return of(
            actions.loginSuccess({ username: action.payload.email }),
            actions.getSavedViews(),
            actions.getLayers(),
            actions.getAPIKey()
          );
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const logoutEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOGOUT)
    .mergeMap(() => {
      return ajax({
        url: `/logout`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(() => {
          window.location.reload();
        })
        .mergeMap(() => {
          return of(actions.logoutSuccess());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const getAPIKeyEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_API_KEY)
    .mergeMap(() => {
      return ajax({
        url: `/api/v1/api-key`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          return of(actions.getAPIKeySuccess(response.response.response.key));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * saved views
 */

export const getSavedViewsEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_VIEWS)
    .mergeMap(() => {
      const { main } = store.value;
      const { isLoggedIn } = main;
      return ajax({
        url: `/api/v1/views`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.receivedViews([]));
          }
          if (!isLoggedIn) {
            return of(
              actions.loginSuccess({ username: response.response.response.user }),
              actions.receivedViews(response.response.response.views)
            );
          }
          return of(actions.receivedViews(response.response.response.views));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const saveViewEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SAVE_VIEW)
    .mergeMap(action => {
      const { main, mapcontainer } = store.value;
      const { currentIndicator, currentGeography, pools, savedViews } = main;
      const { viewport } = mapcontainer;
      const { latitude, longitude, zoom } = viewport;
      const poolIds = pools.map(p => p.geoid);
      const body = {
        title: action.payload,
        latitude,
        longitude,
        zoom,
        indicator: currentIndicator.id,
        geography: currentGeography.id,
        pools: poolIds,
      };
      return ajax({
        url: `/api/v1/views`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          const existingViews = [...savedViews];
          existingViews.unshift(response.response.response);
          return of(actions.receivedViews(existingViews));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const deleteViewEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_DELETE_VIEW)
    .mergeMap(action => {
      const { main } = store.value;
      const { savedViews } = main;
      return ajax({
        url: `/api/v1/views`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: action.payload }),
      })
        .mergeMap(() => {
          const remainingViews = savedViews.filter(v => v.id !== action.payload);
          return of(actions.receivedViews(remainingViews));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const loadSavedViewEpic = (action$, store, { of }) => {
  return action$
    .ofType(types.MAIN_LOAD_SAVED_VIEW)
    .mergeMap(action => {
      const { latitude, longitude, zoom } = action.payload;

      const { geography /* ,  pools */ } = action.payload;
      const actionsToDo = [
        actions.setGeography(geography),
        // imported action from mapcontainer... probably bad and should just be moved into main
        setViewport({
          latitude,
          longitude,
          zoom,
        }),
      ];
      // TO DO: load pools something like this
      // if (pools) {
      //   actionsToDo.push(...pools.map(pool => actions.createPool({ poolId: pool })));
      // }
      return of(...actionsToDo);
    })
    .catch(() => []);
};

export const getViewByIdEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_VIEW_BY_ID)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography } = main;

      return ajax({
        url: `/api/v1/view/${action.payload}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          const savedView = response.response.response;
          if (savedView) {
            return of(actions.loadSavedView(savedView));
          }
          return of(actions.setGeography(currentGeography.id));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * import
 */

export const getLayersEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_LAYERS)
    .mergeMap(() => {
      return ajax({
        url: `/api/v1/layers`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.layersSuccess([]));
          }
          const layers = response.response.response.map(l => ({
            ...l,
            id: parseInt(`99999${l.id}`, 10), // hopefully ensures this id is different from regular indicators
            layerId: l.id, // <- layerId will be the one in the database, and should be used in any API methods
            formatValue: d => d,
            getYearDisplay: d => d,
          }));
          return of(actions.layersSuccess(layers));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const uploadEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_UPLOAD_FILE)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/layers/`,
        method: 'POST',
        body: action.payload, // FormData
      })
        .mergeMap(response => {
          return of(actions.uploadSuccess(response.response.response));
        })
        .catch(error => {
          console.log(error); // where is the response text??
          return of(
            actions.uploadError({
              type: 'upload',
              errors: [error],
            })
          );
        });
    })
    .catch(() => []);
};

export const saveLayerEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SAVE_LAYER)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/save/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          const actualResponse = response.response.response;
          const status = response.response.responseCode;
          if (status !== 200) {
            return of(
              actions.saveLayerError({
                type: 'import',
                errors: [response.response.responseMessage],
              })
            );
          }
          if (actualResponse && actualResponse.errors && actualResponse.errors.length) {
            // assumes any 'errors' array means geography errors, which prob isn't great to assume
            return of(
              actions.saveLayerError({
                type: 'geometry',
                errors: actualResponse.errors,
              })
            );
          }
          return of(actions.saveLayerSuccess(actualResponse), actions.getLayers());
        })
        .catch(error => {
          console.log(error);
          let errorObject = { type: 'import', errors: [error] };
          if (error.status === 500) {
            errorObject = { type: 'import', errors: error.response.response.errors };
          }
          return of(actions.uploadError(errorObject));
        });
    })
    .catch(() => []);
};

export const deleteLayerEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_DELETE_LAYER)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/layers`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: action.payload }),
      })
        .mergeMap(() => {
          return of(actions.getLayers());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const layerDataEpic = (action$, store, { of, getJSON }) => {
  return action$
    .ofType(types.MAIN_LOAD_LAYER_DATA)
    .mergeMap(action => {
      const { type, id, layerId } = action.payload;
      if (type === 'csv') {
        return getJSON(`/api/v1/indicator/layer/${layerId}`)
          .mergeMap(res => {
            const { response } = res;
            // data for each geography requested (possibly two, including state level)
            const successData = {
              [response.geography]: {
                indicator: id,
                geography: response.geography,
                data: response.data,
              },
            };
            const actionsToDo = [];
            actionsToDo.push(
              actions.loadIndicatorDataSuccess({
                // send back the indicator/geog actually requested (e.g. tracts) for clarity
                indicator: id,
                geography: response.geography,
                geographies: successData, // contains the actual data (state and/or e.g. tracts)
              })
            );
            const { main } = store.value;
            const { classificationMethod } = main;
            let newClassification = classificationMethod;
            if (newClassification.toLowerCase() === 'quartiles')
              newClassification = 'Equal Interval';
            actionsToDo.push(actions.setClassificationMethod({ method: newClassification })); // updates choropleth classification if needed)
            // clear geojson layer. not strictly necessary but UI would need work to support two custom layers
            // actionsToDo.push(actions.setOverlayLayer(null));
            return of(...actionsToDo);
          })
          .catch(error => of(actions.testError(error)));
      }
      // geojson/shapefile layer
      return getJSON(`/api/v1/layer/${layerId}`)
        .mergeMap(res => {
          return of(actions.addOverlay({ ...action.payload, geojson: res }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * export
 */

export const mapExportEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_EXPORT_MAP)
    .mergeMap(action => {
      // the html canvas (maybe? it was removed from DOM), png dataURL, and blob format are available here
      // const { canvas, dataURL, blob } = action.payload;
      const { dataURL } = action.payload;

      // TO DO: replace below with correct endpoint and header or body or whatever, to send image
      return ajax({
        url: `/api/v1/exporter`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          imageData: dataURL.split(',')[1],
        },
        responseType: 'blob',
      })
        .mergeMap(({ response }) => {
          // const blob = new Blob([response], { type: 'application/zip' });
          const objectUrl = URL.createObjectURL(response);
          window.open(objectUrl);
          return of(actions.exportSuccess());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const policiesEpic = (action$, store, { of, getJSON, ajax }) => {
  return action$
    .ofType(types.MAIN_GET_POLICIES)
    .mergeMap(action => {
      if (!action.payload) {
        // default, no feature selected
        const { main } = store.value;
        if (main.defaultPolicies)
          return of(actions.policiesSuccess({ policies: main.defaultPolicies }));
        return getJSON(`/api/v1/policy`)
          .mergeMap(res => {
            const { response } = res;
            return of(actions.policiesSuccess({ policies: response }));
          })
          .catch(error => {
            return of(actions.testError(error));
          });
      }
      return ajax({
        url: `/api/v1/policy`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          const policies = response.response.response;
          return of(actions.policiesSuccess({ ...action.payload, policies }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const customScoreEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SET_CUSTOM_SCORE)
    .mergeMap(action => {
      const { main } = store.value;
      const { indicatorList, currentGeography, classificationMethod, allIndicatorData } = main;
      const hpiIndicators = indicatorList
        .filter(d => d.weight > 0)
        .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
        .map(i => i.id);
      // below, if null payload or custom score is actually the same as default HPI score
      if (
        !action.payload ||
        (!action.payload.domains.length &&
          hpiIndicators.every(i => action.payload.indicators.indexOf(i) !== -1))
      ) {
        // classification may need to be reset if the rank data had been rescaled
        return of(
          actions.customScoreSuccess(null),
          actions.setClassificationMethod({ method: 'Quartiles' }),
          actions.setGeography(currentGeography.id) // geog may have changed; this will load data for default indicator if needed
        );
      }
      const domains = indicatorList
        .filter(domain => domain.weight)
        .reduce((domainWeights, domain) => {
          const customWeight = action.payload.domains.find(d => d.domain === domain.id);
          const weight = {
            domain: domain.id,
            weight: customWeight ? customWeight.weight : domain.weight,
          };
          return [...domainWeights, weight];
        }, {});
      // unique id for this set of domain weights and indicators
      const id = domains
        .reduce(
          (concatenated, d) => concatenated.concat(d.domain.toString()).concat(d.weight.toString()),
          ''
        )
        .concat(
          action.payload.indicators.reduce(
            (concatenated, d) => concatenated.concat(d.toString()),
            ''
          )
        );
      if (
        allIndicatorData[currentGeography.id][id] &&
        allIndicatorData[currentGeography.id][id].all
      ) {
        return of(
          actions.customScoreSuccess({ id, data: null }),
          actions.setClassificationMethod({ method: classificationMethod })
        );
      }
      return ajax({
        url: `/api/v1/custom/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...action.payload,
          geography: currentGeography.id,
          domains,
        }),
      })
        .mergeMap(response => {
          return of(
            actions.customScoreSuccess({ id, data: response.response.response }),
            actions.setClassificationMethod({ method: classificationMethod })
          );
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const vulnerabilitiesEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SET_MULTIPLE_VULNERABILITIES)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, defaultIndicator, defaultClassification } = main;

      if (!action.payload) {
        return of(
          actions.setIndicator(defaultIndicator.id),
          actions.setClassificationMethod({ method: defaultClassification })
        );
      }

      return ajax({
        url: `/api/v1/vulnerability/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          geography: currentGeography.id,
          indicator: action.payload,
        }),
      })
        .mergeMap(response => {
          if (main.pools && main.pools.length) {
            return ajax({
              url: `/api/v1/vulnerability/`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                geography: currentGeography.id,
                indicator: action.payload,
                geoids: main.pools.map(p => p.geoid),
              }),
            }).mergeMap(poolResponse => {
              return of(
                actions.multipleVulnerabilitiesSuccess({
                  data: response.response.response,
                  pools: poolResponse.response.response,
                })
              );
            });
          }
          return of(actions.multipleVulnerabilitiesSuccess({ data: response.response.response }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const addressSearchEpic = (action$, store, { getJSON, of }) => {
  return action$
    .ofType(types.MAIN_SET_SEARCH_TERM)
    .mergeMap(action => {
      const searchTerm = action.payload;
      const { env } = store.value.main;
      const { maxBounds, geocodingFilter } = env;
      const bounds = [...maxBounds[0], ...maxBounds[1]].join(',');
      // eslint-disable-next-line max-len
      const geocodeURL = ''.concat(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchTerm)}`,
        '.json?language=en&country=US&types=locality,neighborhood,address,poi',
        `&bbox=${bounds}`,
        '&access_token=pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg'
      );
      if (searchTerm.length > 2) {
        return getJSON(geocodeURL).map(response => {
          if (response.features && response.features.length) {
            const features = response.features.filter(d =>
              d.context.find(c => c.short_code === geocodingFilter)
            );
            if (features.length) {
              return actions.setAddressSearchResults({
                searchTerm,
                results: features.map(d => ({
                  geoid: d.id,
                  geometry: d.geometry,
                  name: d.place_name,
                  address: true,
                })),
              });
            }
            return actions.setAddressSearchResults();
          }
          return actions.setAddressSearchResults();
        });
      }
      return of(actions.setAddressSearchResults());
    })
    .catch(error => of(actions.testError(error)));
};
