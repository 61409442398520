//  covid-panel.action.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

import * as types from './heat-resources.actionType';

export function loadResourcesData(payload) {
  return {
    type: types.HEATRESOURCESPANEL_LOAD_DATA,
    payload,
  };
}

export function dataSuccess(payload) {
  return {
    type: types.HEATRESOURCESPANEL_DATA_SUCCESS,
    payload,
  };
}
