//  update.route.js
//  Project hpi
//
//  Created by Axis Maps on 9/8/2020, 3:02:37 PM
//

export default {
  path: '/update',
  component: () => import('./update.component'),
};
