//  map-container.actionType.js
//  Project hpi
//
//  Created by Axis Maps on 4/7/2020, 9:30:49 AM
//

export const MAPCONTAINER_TEST = 'MAPCONTAINER_TEST';
export const MAPCONTAINER_TEST_SUCCESS = 'MAPCONTAINER_TEST_SUCCESS';

// send current map bounds to app
export const MAPCONTAINER_SET_BOUNDS = 'MAPCONTAINER_SET_BOUNDS';
export const MAPCONTAINER_SET_VIEWPORT = 'MAPCONTAINER_SET_VIEWPORT';

export const MAPCONTAINER_GET_GEOJSON = 'MAPCONTAINER_GET_GEOJSON';
export const MAPCONTAINER_GEOJSON_SUCCESS = 'MAPCONTAINER_GEOJSON_SUCCESS';

export const MAPCONTAINER_SET_PROBE_DATA = 'MAPCONTAINER_SET_PROBE_DATA';
export const MAPCONTAINER_SET_SELECTED_FEATURE_SOURCE_DATA =
  'MAPCONTAINER_SET_SELECTED_FEATURE_SOURCE_DATA';
export const MAPCONTAINER_SET_RANK_FEATURE_SOURCE_DATA =
  'MAPCONTAINER_SET_RANK_FEATURE_SOURCE_DATA';
export const MAPCONTAINER_SET_TOP_RANKED_FEATURE_SOURCE_DATA =
  'MAPCONTAINER_SET_TOP_RANKED_FEATURE_SOURCE_DATA';
