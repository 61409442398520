//  verify.route.js
//  Project hpi
//
//  Created by Axis Maps on 3/29/2022, 12:50:12 PM
//

export default {
  path: '/verify/:token',
  component: () => import('./verify.component'),
};
