//  covid-panel.actionType.js
//  Project hpi
//
//  Created by Axis Maps on 7/29/2020, 4:51:39 PM
//

export const COVIDPANEL_TEST = 'COVIDPANEL_TEST';
export const COVIDPANEL_TEST_SUCCESS = 'COVIDPANEL_TEST_SUCCESS';

export const COVIDPANEL_LOAD_DATA = 'COVIDPANEL_LOAD_DATA';
export const COVIDPANEL_DATA_SUCCESS = 'COVIDPANEL_DATA_SUCCESS';
