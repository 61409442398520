//  index.js
//  Project hpi
//
//  Created by Axis Maps on 5/22/2020, 11:01:08 AM
//

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./display-panel.component'),
  loading: () => null,
});
